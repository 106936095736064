import { ReactComponent as StarIcon } from '../assets/images/icons/screener/star.svg';
import { ReactComponent as CopyIcon } from '../assets/images/icons/screener/copy.svg';
import { ReactComponent as PlusIcon } from '../assets/images/icons/screener/plus.svg';
import { ReactComponent as IconRemove } from '../assets/images/icons/screener/trash.svg';

export const ONE_THOUSAND = 1000;
export const ONE_THOUSAND_AND_HALF = 1500;
export const FIVE_MINUTES = 300000;
export const SIXTY_THOUSAND = 60000;
export const TWENTY_THOUSAND = 20000;
export const ALLOWED_RESOLUTION_INDEX = 14;
export const MIN_COLUMNS_CLEAR = 4;
export const HALF_YEAR_INDEX = 18;
export const symbolColumn = 17;
export const MODAL_TITLES = {
  CREATE_FOLDER: 'Create folder',
  CREATE_SCREEN: 'Create screen',
  CREATE_PRESET: 'Create preset',
  CREATE_DEEPLIST: 'Create deeplist',
  CREATE_DEEPLIST_FOLDER: 'Create deeplist folder',
  CREATE_DEEPLIST_PRESET: 'Create deeplist preset',
  CREATE_DASHBOARD: 'Create dashboard',
  CREATE_SHARED_ITEM: 'Save the item',
  CREATE_DASHBOARD_PRESET: 'Create dashboard preset',
  CREATE_DATA_PANEL: 'Create data panel',
  CREATE_DATA_PANEL_PRESET: 'Create data panel preset',
  CREATE_FOLDER_PRESET: 'Create folder preset',
  CREATE_FOLDER_COLUMNS: 'Create folder columns',
  DUPLICATE: 'Duplicate',
  MOVE_TO: 'Move to',
  PRESET_MOVE_TO: 'Preset move to',
  DELETE: 'Delete',
  EDIT: 'edit',
  FILTERS: 'Filters',
  CREATE: 'Create',
  EDIT_COLUMNS_FOLDER: 'edit folders',
  DELETE_COLUMNS_FOLDER: 'delete folders',
  CREATE_COLUMNS_SET: 'Create columns sets',
  CREATE_COLUMNS_SET_PRESET: 'Create columns sets preset',
  MOVE_COLUMN_TO: 'Move column set to',
  REMOVE_COLUMN: 'Remove column set',
  DUPLICATE_COLUMN: 'Duplicate column set',
  EDIT_COLUMN: 'Edit column set',
  DELETE_COLUMN: 'Delete column set',
  REMOVE: 'Remove',
  RENAME: 'Rename',
};

export const CURRENT_PAGE_DEFAULT = {
  INIT_VALUE: 0,
  SYMBOLS_AMOUNT: 200
};

export const EDIT_MODAL_TYPES = {
  COLUMN: 'column',
  FOLDER: 'folder',
  SCREEN: 'screen',
  SCREENER: 'screener',
};

export const EDIT_MODAL_STRINGS = {
  column: { head: 'changeColumnName', label: 'columnName', placeholder: 'typeColumnName' },
  folder: { head: 'changeFolderName', label: 'folderName', placeholder: 'typeFolderName' },
  screen: { head: 'changeScreenName', label: 'screenName', placeholder: 'typeScreenName' },
};

export const ONE_HUNDRED = 100;
export const TWO_HUNDRED = 200;
export const ONE_HUNDRED_HALF = 150;
export const SAVE_USER_STATE_DELAY = 1800;
export const FIFTY = 50;
export const TWEET_LIMIT = 271;

export const MAX_LENGTH_TEXT_AREA = '270';
export const MAX_LENGTH_COUNT = '40';
export const DEFAULT_MAX_LENGTH = '524288';

export const KEY_CODES = {
  ENTER: 'Enter',
  NUM_ENTER: 'NumpadEnter',
  ESC: 'Escape',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  BACKSPACE: 'Backspace',
  SPACE: 'Space',
  LETTERS: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$',
};

export const PROTOCOL = {
  HTTP: 'http://',
  HTTPS: 'https://',
};

export const CURRENT_TAB_NAME = {
  SAVED: 'Saved',
  PRESET: 'Presets',
  DROP_LISTS: 'labelCopy',
  TWITTER_LISTS: 'twitterCopy',
  COLUMNS_SET: 'Columns set'
};
export const LABEL_EXPORT = 'labelExportCSV';
export const FILE_FORMATS = {
  CSV: 'csv',
  EXCEL: 'excel',
  XLSX: 'xlsx'
};

export const LONG_COLUMN = [
  { shortName: 'WCR', name: 'Weekly Closing Range' },
  { shortName: 'DCR', name: 'Daily Closing Range' },
];

export const DATA_TYPES = [
  'percent:2',
  'candle',
];

export const DEFAULT_COLUMNS = [
  'Symbol',
  'symbolIndex',
];

export const SOCIALS_TYPE = {
  TWITTER: 'twitter'
};

export const SUBSCRIPTION_TYPE = {
  FREE: 'free',
  ADMIN: 'admin',
  EDITOR: 'presets_editor'
};

export const MS_INTERVAL = {
  THROTTLING_SCROLL: 600,
  HALF_SECOND: 500,
  SECOND: 1000,
  TWO_SECOND: 2000,
  MINUTE: 40000,
  DELAY: 1000,
};

export const CHECK_VERSION_INTERVAL = 300000;

export const defaultPage = [0, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT];

export const CALC_VALUE = {
  ITEM: 90,
  SUB_ITEM: 120,
  IS_OPEN: 540,
  IS_CLOSE: 450,
  IS_OPEN_SUB_ITEM: 660,
  IS_CLOSE_SUB_ITEM: 567,
};

export const COLUMNS_CLASS = {
  SELECT_BUTTON: 'bottomButton',
  SELECT_BUTTON_DISABLED: 'bottomButtonDisabled'
};

export const POPUP_POSITIONS = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const NEW_SCREEN_DATA = {
  SAVED: 'Saved',
  COLUMNS: 'columns',
  FOLDER: 'folder',
  SCREENER: 'screener',
  PRESETS: 'presets'
};

export const ARRAY_RESOLUTIONS = ['1', '3', '5', '15', '30', '60', '65', '78', '120', '240', '1D', '1W', '1M'];

export const ESTIMATE = 'Estimate';

export const TV_EVENTS = {
  STUDY_EVENT: 'study_event',
  MOUSE_UP: 'mouse_up',
  DRAWING: 'drawing',
  DRAWING_EVENT: 'drawing_event',
  LAYOUT_CHANGED: 'layout_changed',
  LAYOUT_TO_BE_CHANGED: 'layout_about_to_be_changed',
  AUTO_SAVE_EVENT: 'onAutoSaveNeeded',
  CLICK: 'click',
  TOGGLE_SIDEBAR: 'toggle_sidebar',
  LINE_TOOL_CHANGED: 'onSelectedLineToolChanged',
  REMOVE: 'remove',
  MOUSE_DOWN: 'mouse_down',
  KEY_DOWN: 'keydown',
  MOUSEMOVE: 'mousemove',
  MOVE: 'move',
  POINTS_CHANGED: 'points_changed',
  CREATE_EVENT: 'create',
  PROPERTIES_CHANGED: 'properties_changed'
};

export const TEMPLATE_TYPE = {
  CHART_WIDGET: 'chartWidget',
  CHART_WIDGET_MOBILE: 'chartWidgetMobile',
  CHART_TEMPLATE: 'chartTemplate',
  CHART_SETTINGS: 'settingsLayout',
  DRAWING_TEMPLATES: 'drawingTemplate',
  SCREENER: 'screener',
  PRESET: 'presets',
  CHART: 'chart',
  WATCHLIST: 'watchList',
  WATCHLIST_PRESETS: 'watchlistPresets',
  COLUMN_SET: 'columns',
  COLUMN_SET_PRESET: 'columnsPreset',
  ALERTS: 'alerts',
  DATA_PANEL: 'dataPanel',
  DATA_PANEL_PRESET: 'presetsDataPanel',
  DASHBOARDS: 'userDashboard',
  DASHBOARDS_PRESET: 'userDashboardPresets',
};

export const PLAN_TYPE = {
  PREMIUM: 'premium',
  WATCHLIST: 'watchList'
};

export const IMG_PATH = process.env.REACT_APP_ICON_PATH;
export const SECTOR_IMG_PATH = process.env.REACT_APP_SECTOR_ICON_PATH;

export const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet?text=';
export const TWITTER_CHARACTER_LIMIT = 280;
export const FREE = 'free';
export const SPACE_COUNT = 4;

export const VIEW_TYPES = {
  TABLE: 'table',
  CHART: 'chart',
  COLUMNS: 'columns',
  TARGET: 'target',
};

export const PREPARE_COLOR_DATA = {
  DEFAULT: 'DEF',
  EMPTY: '',
  COLOR_TXT: 'colorTxt',
  COLOR_BG: 'colorBg',
  COLOR_DOT: 'colorDot',
};

export const STYLE_GENERATOR_SETTINGS = {
  MIN_WIDTH: '60%',
  WIDTH: 'fit-content',
  HEIGHT: '25px',
  DISPLAY: 'flex',
  JUSTIFY_CONTENT: 'center',
  PADDING: '0 5px',
  ALIGN_ITEMS: 'center',
  COLOR: 'white',
  BORDER_RADIUS: '4px'
};

export const TABLE_ITEM_SETTINGS = {
  TYPE_CANDLE: 'candle',
  TYPE_PERCENT: 'percent',
  TYPE_TEXT_CAPITALIZE: 'text_capitalize',
  VALUE_NA: 'N/A',
  VALUE_EMPTY: '-',
  DOT: 'colorDot'
};

export const FALSY_VALUES = ['NaN', 'n/a', 'N/A', null, ''];

export const LAST_ITEM_SCREENER_WIDTH = 60;

export const SCREENER_TYPES_LABEL = {
  PRESET: 'preset'
};

export const TABLE_ITEM_COLORS_SETTINGS = {
  COLUMN_NAME: 'Last',
  INDUSTRY_NAME: 'Industry',
  SECTOR_NAME: 'Sector',
  GROUP_NAME: 'Group',
  SUB_INDUSTRY_NAME: 'Sub-Industry',
  DEFAULT_COLOR: '#283555',
  DEFAULT_COLOR_DARK: '#FFFFFF',
  DEFAULT_WHITE: '#FFF',
  TRADING_VIEW: '#1B202C',
  DEFAULT_PANEL_COLOR: '#7E8699',
  LESS_COLOR: '#F55BAE',
  HIGH_COLOR: '#3A75E8',
};

export const topIds = {
  TOP_INDUSTRY_STATUS_INDEX: 258,
  TOP_SUB_INDUSTRY_STATUS_INDEX: 65,
  TOP_GROUP_STATUS_INDEX: 66,
  TOP_SECTOR_STATUS_INDEX: 67,
};
export const HEX_COLORS = {
  BLUE: '3A75E8',
  BLUE_DARK: '6D9EFF',
};

export const DEFAULT_COLUMNS_INDEX = [
  0, 22, 3, 274, 218, 33, 62, 34, 258, 1753, 1754, 1755, 1756, 65, 66, 67, 1757, 1758, 1759, 1760, 1997, 20, 21, 29, 30
];

export const MIN_COLUMN_COUNT = 10;

export const MULTI_CHART_COLUMNS = 8;

export const VOLUME_COLUMN_INDEX = 6;

export const GET_DEFS_URLS_TIMEOUT = 60000;

export const SCREENS_WITH_LAYOUT = [
  '/',
  '/screener',
  '/screener/undefined',
  '/screener/',
  '/screener/undefined/',
  '/deeplist',
  '/deeplist/undefined',
  '/deeplist/',
  '/deeplist/undefined/',
  '/account-settings/profile',
  '/account-settings/payment',
  '/account-settings/security',
  '/manage-alerts',
  '/manage-alerts/',
  '/manage-alerts/undefined',
  '/dashboard',
  '/dashboard/',
  '/dashboard/undefined',
  '/table',
  '/education',
];

export const buttonData = [
  {
    titleValue: 'copyTo',
    tooltipValue: 'labelCopyToFavorites',
    popUpValue: 'copySymbolsToFavorites',
    icon: StarIcon,
    watchListIcon: PlusIcon,
    className: 'buttonControlElementCopyTo'
  },
  {
    titleValue: 'copy',
    tooltipValue: 'labelCopy',
    popUpValue: 'copySymbols',
    icon: CopyIcon,
    className: 'buttonControlElementCopy'
  },
  {
    titleValue: 'delete',
    tooltipValue: 'deleteSelectedSymbols',
    popUpValue: '',
    icon: IconRemove,
    className: 'buttonControlElementDelete'
  }
];

export const BUTTON_GROUP_SETTINGS = {
  DELETE: 'delete',
  COPY_TO: 'copyTo',
  COPY: 'copy',
  REMOVE_CLASS: 'screenerButtonGroupRemove',
  COMMON_CLASS: 'screenerButtonGroup'
};

export const ALLOWED_TITLE_SEARCH_LENGTH = 31;
export const ALLOWED_TITLE_LENGTH = 10;
export const ALLOWED_TITLE_DL_LENGTH = 12;
export const ALLOWED_TITLE_TARGET_DROP_DOWN = 14;
export const ALLOWED_TITLE_DL_LENGTH_FIXED = 15;
export const ALLOWED_TITLE_LENGTH_DROP_DOWN = 12;
export const ALLOWED_TITLE_DELETE_MODAL_LENGTH = 30;
export const MAX_COLUMN_WIDTH = 280;

export const TABLE_COLUMNS_MIN_SIZES = {
  '-1': 115,
  '-2': 106,
  17: 100,
  default: 60
};

export const DEFAULT_SIZE_STATE = {
  17: {
    minWidth: 148,
    startWidth: 100,
    maxWidth: 200
  }
};

export const SELECT_SIZE_BY_TYPE = {
  datetime: 150,
  date: 120,
  int: 130,
  text: 200
};

export const TABLE_COLUMNS_MAX_SIZES = {
  17: 200,
  '-1': 160,
  '-2': 180,
  default: 0
};

export const ADDITIONAL_DATA_COLUMNS = {
  EARNINGS: 'earningsValue',
  EX_DIVIDENT: 'dividendValue',
  EARNINGS_TIME: 'earningTimeValue',
  TYPE: 'type',
  IS_ETF: 'isETF'
};

export const CLEAR_COLUMNS = {
  SCREENER: 19,
  CHART: 20,
  VOLUME_BARS: 10,
};

export const MOUSE_BUTTONS = {
  LEFT: 0,
  CENTER: 1,
  RIGHT: 2,
};

export const TOUCH_EVENT = {
  TOUCH_START: 'touchstart',
  TOUCH_MOVE: 'touchmove',
  TOUCH_END: 'touchend',
};

export const MOUSE_EVENTS = {
  RESIZE: 'resize',
  WHEEL: 'wheel'
};

export const RESIZE_COLUMN_CLASSES = {
  max: 'maxRight',
  min: 'maxLeft',
};

export const FILTER_SETTINGS = {
  ZERO: 0,
  BILLION: 100000000
};

export const SAVE_VISIBILITY_SYMBOLS = 'visibilitySetting';
export const WORKER_COMMANDS = {
  START: 'start',
  STOP: 'stop',
  MESSAGE: 'message'
};

export const ROW_STYLES = [
  {
    title: 'normal',
    size: 44
  },
  {
    title: 'medium',
    size: 38
  },
  {
    title: 'small',
    size: 32
  }
];

export const ACTIVE_COLOR = {
  color: '#6D9EFF'
};

export const SLICE_DAY_SETTINGS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
  MINUTES: 'minutes'
};

export const ONE_AND_HALF = 1.5;
export const favoriteTimer = 400;

export const GET_INDEX_COLUM = {
  COLOR_INDUSTRY: 11,
};

export const DEFAULT_ITEM = -1;

export const TYPE_FIND_ITEM = {
  ID: 'id'
};

export const INITIAL_SCREENER_LAYOUT = {
  chartWidth: 0,
  statsOpen: false,
};

export const HALF_CHART = {
  chartWidth: 50,
  statsOpen: false,
};

export const THIRD_CHART = {
  chartWidth: 46,
  statsOpen: false,
};

export const CHART_50 = 50;
export const CHART_46 = 46;

export const SYMBOL_WIDTH = 190;
export const SYMBOL_HALF_WIDTH = 160;
export const SYMBOL_FULL_WIDTH = 125;

export const DASHBOARD_DROPDOWN_WIDTH = 335;

export const CHART_CONSTRAINTS = {
  max: 90,
  maxTwooColumnData: 57,
  maxOneColumnData: 73.5,
  min: 10,
  minTwooColumnData: 15,
};

export const CHECKED = 'Checked';
export const UNCHECKED = 'UnChecked';

export const IMAGE_NAME = {
  ICON_EDIT: 'IconEdit',
  ICON_REMOVE: 'IconRemove',
  ICON_ADD_TO_FOLDER: 'IconAddToFolder',
  FOLDER_REMOVE: 'FolderRemove',
  ICON_COPY: 'IconCopy'
};

export const FIND_NAME = {
  ID: 'id',
  INDEX: 'index',
  RECOMMENDED: 'Recommended',
  FOLDER: 'folder',
  PARENT_ID: 'parentId',
  TITLE: 'title'
};

export const PAGING_SETTINGS = 20;

export const FIELD_STORAGE_COLUMN = 'activeColumnSet';

export const SCREENER_VIEW = 'screenerView';

export const SCREENER_VIEWS = {
  SCREENER: 'screener',
  CHART: 'chart'
};

export const SETTING_BUTTON_CLASS = {
  EMPTY: 'emptyScreen',
  NOT_EMPTY: 'settings'
};

export const FIXED_COLUMNS = [0, 17, 1998];

export const PATH_TYPE = {
  WATCHLIST: '/deeplist',
  SCREENER: '/screener',
  MANAGE_ALERTS: '/manage-alerts',
  DASHBOARD: '/dashboard',
};

export const TABLE_SCREEN_PATH = {
  SCREENER: 'Screener',
  DEEPLIST: 'Watchlist',
  DASHBOARDS: 'Dashboard',
  EDUCATION: 'Education',
};

export const ALERTS_TOAST_SETTING = {
  TO_FIXED: 2,
  MULTIPLY: 100,
  REL_VOL_RULE: 1.5,
  PERCENT_RULE: 0
};

export const COLUMNS_INDEX = {
  INDEX: 0,
  SYM: 17,
  INDUSTRY: 21,
  SYMBOL: 0,
  IS_ETF: 1997,
  ETF: 1998,
  EARNINGS: 33,
  DIVIDEND: 62,
  EARNINGS_TIME: 34,
};

export const REVERSE_SORTING_COLUMNS = [17, 21, 20, 30, 29];

export const ALLOWED_DP_IDS = [0, 1998];

export const EMPTY_ARRAY = 0;

export const LIGHT_LENGTH = 1;

export const STYLE_TYPE = {
  RECOMMENDED: 'recommended',
};

export const HIDE_MENU_RECOMMENDED = {
  ITEM: [3, 4],
  FOLDER: [1]
};

export const TYPE_HANDLER = {
  CLEAR: 'clear',
  SAVE: 'save',
  DEFAULT: 'default'
};

export const EMPTY_STRING = '';

export const DEFAULT_PAGINATION_STATE = [0, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT];

export const HIDEN_SEARCH_ELEMENTS = [
  'TEXTAREA',
  'INPUT'
];

export const LAST_ELEMENT_POSITION_ONE = 60;
export const LAST_ELEMENT_POSITION_SECOND = 180;

export const SUB_ELEMENTS_LENGTH = [3, 1];

export const CORRECT_COLUMN = 1;

export const SYM_COLUMN_INDEX = 17;

export const REMOVE_COLUMN = {
  INDEX: 0,
  SYM: 17,
  FIRST: 1,
  WEIGHT: 1998
};

export const RECOMMENDED_COLUMNS = [3, 218, 8, 7, 6, 21, 11, 14];

export const RECOMMENDED_TYPE = 'resetColumns';

export const SESSION_LAZY_LOAD = 'retry-lazy-refreshed';

export const STATUS_RELOAD_COMPONENT = {
  TRUE_STATUS: 'true',
  FALSE_STATUS: 'false'
};

export const ALERT_COLUMNS_SETTINGS = 'alertColumns';
export const STATUS_ALERT_COLUMN = -1;

export const SCREENER_PADDINGS = 40;
export const SCREENER_WIDTH_PADDINGS = 15;
export const OVERSCAN_ROW_COUNT = 10;
export const COLUMN_COUNT = 1;
export const SCREENER_PADDING_WIDGET = 37;

export const DATA_PANEL_WIDTH = 33;
export const DATA_PANEL_ADD_WIDTH = 17;
export const SCREENER_MIN_WIDTH = 10;

export const LAYOUT_DELTA_WIDTH = 1920;
export const SCREENER_TARGET_HEIGHT = 360;
export const SCREENER_TARGET_MORE_HEIGHT = 450;
export const DEEPLIST_OFFSET = 195;
export const DEEPLIST_OFFSET_CONTAINER = 100;

export const holdingsSorting = { indexElement: 1998, sortValue: 1 };
export const holdingsIndex = 1998;

export const MAX_HISTORY_SEARCH_LENGTH = 100;

export const industryColumns = [
  218, 1739, 1974
];

export const CLICKABLE_CELLS_NAMES = ['Sector', 'Industry', 'Group', 'Sub-Industry'];
export const CLICKABLE_CELLS_INDEXES = [20, 21, 29, 30];
