import { useTranslation } from 'react-i18next';
import {
  DashboardItemsDialogTitle,
  DashboardItemsDialogActionTypes,
  DashboardItemsDialogTitleEntity,
  DashboardTypeTitles
} from '../types/constants';
import { DialogItemEntityType } from '../../DashboardsItemsDropdown/types/constants';
import { TDialogData } from '../types/types';

interface IUseDialogData {
  (): {
    getTitle: (data: TDialogData, isFirstDuplicate: string | undefined) => string;
  }
}

const UseDialogData: IUseDialogData = () => {
  const { t } = useTranslation();

  const getTitle = (data: TDialogData, firstTitle: string | undefined) => {
    const title = DashboardItemsDialogTitle[data?.type as DashboardItemsDialogActionTypes];
    const entityString = DashboardItemsDialogTitleEntity[data?.entityType as DialogItemEntityType];
    if (data?.type === DashboardItemsDialogActionTypes.DELETE) {
      return t('confirmModalTitle');
    }
    if (data?.type === DashboardItemsDialogActionTypes.DUPLICATE) {
      const dashboardItemTypeName = DashboardTypeTitles[data?.item?.type as keyof typeof DashboardTypeTitles];
      return t(title, { nameOfType: `${t(dashboardItemTypeName)} ${firstTitle}` });
    }
    if (data?.type === DashboardItemsDialogActionTypes.ADD) {
      return `${t(title)} ${t(entityString).toLowerCase()}`;
    }

    return t(title, { nameOfType: t(entityString).toLowerCase() });
  };

  return {
    getTitle
  };
};

export default UseDialogData;
