import { shallowEqual, useSelector } from 'react-redux';
import React, { useContext } from 'react';
import useMainTableWorker from '../hooks/useMainTableWorker';
import styles from '../sass/TableScreener.module.scss';
import DataPanel from '../../../components/DataPanel/DataPanel';
import ScreenerViewMain from './ScreenerViewMain';
import useResize from '../../../utils/hooks/useResize';
import TableContext from '../context/TableContext';

const ScreenerView = () => {
  const {
    isWatchlist,
    getScreenerLayout,
    getCurrentInnerWidth,
    dataPanel,
    isAlertsTab,
    activeSymbolIndexValue,
    alertFilters
  } = useContext(TableContext);

  const isHideMenu = useSelector((state) => state.accountState.userSettings.isHideSideMenu, shallowEqual);
  const { initCurrentGridTemplate } = useResize(getScreenerLayout, getCurrentInnerWidth);
  const {
    sectorIndustryWidgetHandler,
    tableData,
    columnsData,
    sortHandler,
    sortStatus,
    clearSortingHandler,
    sortData,
    setNewSortData,
    setLoader,
    handleSetAllItems,
    setItems,
    setTopScreenerIndex,
    setVisibleItemsTable,
    setOpenETF,
    volumeBarsData,
    additionalData,
    loaderStatus,
    isHoldingView,
    tableIndexesData
  } = useMainTableWorker(
    () => undefined,
    isAlertsTab,
    activeSymbolIndexValue,
    alertFilters,
  );
  return (
    <div
      id="screenerLayout"
      className={`${styles.viewGraphic} ${isHideMenu && styles.shortly}`}
      style={{ gridTemplateColumns: initCurrentGridTemplate }}
    >
      <ScreenerViewMain
        tableData={tableData}
        columnsData={columnsData}
        sortHandler={sortHandler}
        sortStatus={sortStatus}
        clearSortingHandler={clearSortingHandler}
        sortData={sortData}
        setNewSortData={setNewSortData}
        setLoader={setLoader}
        handleSetAllItems={handleSetAllItems}
        setItems={setItems}
        setTopScreenerIndex={setTopScreenerIndex}
        setVisibleItemsTable={setVisibleItemsTable}
        setOpenETF={setOpenETF}
        volumeBarsData={volumeBarsData}
        additionalData={additionalData}
        loaderStatus={loaderStatus}
        isHoldingView={isHoldingView}
        tableIndexesData={tableIndexesData}
        sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
      />
      <DataPanel
        isWatchList={isWatchlist}
        dataPanel={dataPanel}
        sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
      />
    </div>
  );
};

export default ScreenerView;
