import React from 'react';
import PropTypes from 'prop-types';

import { shallowEqual, useSelector } from 'react-redux';
import { TABLE_SCREEN_PATH } from '../../../constants/screener';
import styles from '../sass/DataPanel.module.scss';
import { PANEL_CONTENT_GRID_TYPES } from '../dataPanelConstants';
import DataPanelContentCartItem from './DataPanelContentCartItem';

const DataPanelContent = ({
  dataPanelType,
  dataPanels,
  dataPoints,
  screenerId,
  getPointData,
  symbolIndex,
  widget,
  changeSymbolForGroup,
  sectorIndustryWidgetHandler
}) => {
  const lastPath = useSelector((state) => state.accountState.userSettings.lastTablePath, shallowEqual);
  const dataPanelElement = document.getElementById(widget?.id);
  const dataPanelInnerElement = document.getElementById(`dataPanelContent-${widget?.id}`);
  const panelWidth = dataPanelElement ? dataPanelElement.getBoundingClientRect()?.width : 0;
  const panelInnerChildren = dataPanelInnerElement ? dataPanelInnerElement?.children : [];

  const getWidth = (dataPanelsNumber) => {
    const minWidth = 220;
    const maxHeightOfCategory = 395;
    let maxHeightOfDataPanel = maxHeightOfCategory;

    let calculatedWidth = Math.floor(panelWidth / dataPanelsNumber) - 10;
    let lessDataPanels = dataPanelsNumber;

    while (calculatedWidth < minWidth) {
      lessDataPanels -= 1;
      calculatedWidth = panelWidth / lessDataPanels - 10;
      maxHeightOfDataPanel = (maxHeightOfCategory * dataPanelsNumber) / (lessDataPanels + 1) + 10;
    }

    let columnsCount = 1;
    let leftHeightOfColumn = maxHeightOfDataPanel;
    if (panelInnerChildren) {
      Array.from(panelInnerChildren).forEach((child, index) => {
        const childHeight = child.offsetHeight;

        // If the child fits in the current column
        if (leftHeightOfColumn - childHeight - 10 >= 0) {
          if (index === panelInnerChildren.length - 1 && leftHeightOfColumn === maxHeightOfDataPanel) {
            columnsCount += 1;
          }
          leftHeightOfColumn -= childHeight - 10;
        } else {
          // If the child doesn't fit, create a new column
          columnsCount += 1;
          leftHeightOfColumn = maxHeightOfDataPanel - childHeight - 10;
        }
      });
      if (columnsCount > lessDataPanels) {
        maxHeightOfDataPanel += panelInnerChildren[panelInnerChildren.length - 1]?.offsetHeight / 1.5;
      }
      if (columnsCount < lessDataPanels) {
        maxHeightOfDataPanel -= panelInnerChildren[panelInnerChildren.length - 1]?.offsetHeight / 1.5;
      }
    }

    return [calculatedWidth, maxHeightOfDataPanel];
  };

  const getClass = () => {
    if (lastPath !== TABLE_SCREEN_PATH.DASHBOARDS) {
      switch (dataPanelType) {
        case PANEL_CONTENT_GRID_TYPES.COMPACT:
          return styles.compact;
        default:
          return styles.default;
      }
    } return '';
  };

  return (
    <>
      {
      lastPath === TABLE_SCREEN_PATH.DASHBOARDS || widget?.id ? (
        <div
          className={`${styles.dataPanelContent} ${getClass()} ${styles.vertical}`}
        >
          <div
            className={`${styles.dataPanelContentInner} ${widget?.id && styles.dataPanelContentInnerDashboard}
      ${![...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length && styles.dataPanelContentInnerDashboardEmpty}
      ${panelWidth < 450 && styles.dataPanelContentInnerDashboardBigger}`}
            style={{ height: getWidth([...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length)[1] }}
            id={`dataPanelContent-${widget?.id}`}
          >
            {[...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.map((item) => (
              <DataPanelContentCartItem
                key={[item?.title, 'showCart'].join('_')}
                dataPoints={dataPoints}
                data={item}
                screenerId={screenerId}
                getPointData={getPointData}
                symbolIndex={symbolIndex}
                widget={widget}
                changeSymbolForGroup={changeSymbolForGroup}
                width={getWidth([...dataPanels?.leftColumn, ...dataPanels?.rightColumn]?.length)[0]}
                sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={`${styles.dataPanelContent} ${getClass()}`}>
          <div className={styles.dataPanelContentInner}>
            {dataPanels?.leftColumn.map((item) => (
              <DataPanelContentCartItem
                key={[item?.title, 'showCart'].join('_')}
                dataPoints={dataPoints}
                data={item}
                screenerId={screenerId}
                getPointData={getPointData}
                symbolIndex={symbolIndex}
                sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
              />
            ))}
          </div>
          <div className={styles.dataPanelContentInner}>
            {dataPanels?.rightColumn.map((item) => (
              <DataPanelContentCartItem
                key={[item?.title, 'showCart'].join('_')}
                dataPoints={dataPoints}
                data={item}
                screenerId={screenerId}
                getPointData={getPointData}
                symbolIndex={symbolIndex}
                sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
              />
            ))}
          </div>
        </div>
      )
      }
    </>

  );
};
DataPanelContent.propTypes = {
  dataPanelType: PropTypes.string,
  dataPanels: PropTypes.shape({
    leftColumn: PropTypes.arrayOf(PropTypes.shape({})),
    rightColumn: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  widget: PropTypes.shape({
    id: PropTypes.string,
  }),
  dataPoints: PropTypes.shape({}).isRequired,
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  getPointData: PropTypes.func.isRequired,
  changeSymbolForGroup: PropTypes.func,
  symbolIndex: PropTypes.number.isRequired,
  sectorIndustryWidgetHandler: PropTypes.func,
};

DataPanelContent.defaultProps = {
  dataPanelType: 'default',
  screenerId: null,
  widget: {},
  changeSymbolForGroup: () => null,
  sectorIndustryWidgetHandler: () => null
};

export default DataPanelContent;
