const DEFAULT_MODAL_INPUT = {
  label: 'modalInputLabel',
  placeholder: 'modalInputPlaceholder',
};

export const PANEL_CONTENT_GRID_TYPES = {
  DEFAULT: 'default',
  COMPACT: 'compact'
};

export const PANEL_CONTENT_GRID_TYPE_DEFAULT = 'default';
export const PANEL_CONTENT_GRID_TYPE_COMPACT = 'compact';

export const MODAL_TYPES = {
  ADD_CATEGORY: 'addCategory',
  REMOVE_CATEGORY: 'removeCategory',
  RESTORE_DEFAULTS: 'restoreDefaults',
  RENAME_CATEGORY: 'renameCategory',
};

export const MODAL_HEADERS = {
  addCategory: 'addCategoryHeader',
  renameCategory: 'renameCategoryHeader'
};

export const MODAL_ICONS = {
  removeCategory: 'delete',
  restoreDefaults: 'restore'
};

export const MODAL_CONTENT = {
  removeCategory: 'removeCategoryContent',
  restoreDefaults: 'restoreCategoryContent'
};

export const MODAL_ACTIONS_TEXT_CONFIRM = {
  addCategory: 'save',
  renameCategory: 'save',
  removeCategory: 'yes',
  restoreDefaults: 'yes'
};

export const MODAL_ACTIONS_TEXT_DECLINE = {
  addCategory: 'cancel',
  renameCategory: 'cancel',
  removeCategory: 'no',
  restoreDefaults: 'no'
};

export const MODAL_ACTIONS_INPUT = {
  addCategory: DEFAULT_MODAL_INPUT,
  renameCategory: DEFAULT_MODAL_INPUT,
};

export const MODAL_ACTIONS_REVERCE = {
  addCategory: true,
  renameCategory: true,
  removeCategory: false,
  restoreDefaults: false
};

export const CATEGORY_MAX_LENGTH = 20;

export const DATAPOINTS_MAX_LENGTH = 15;

export const INITIAL_SCROLL = 0;

export const MAX_CATEGORY_NAME = 40;

export const DATA_PANEL_ACTION_TYPES = {
  ADD_CATEGORY: 'addCategory',
  REMOVE_CATEGORY: 'removeCategory',
  RESTORE_DEFAULTS: 'restoreDefaults',
};
