import React, {
  useRef, useEffect, useState
} from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import Portal from '@mui/material/Portal';
import styles from '../sass/DashboardComponents.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import { ReactComponent as ChevronBlue } from '../../../assets/images/icons/screener/chevronBlue.svg';
import { ActionOpenDialogParams, IWidgetHeader } from '../types/DashboardComponentsInterfaces';
import DefaultButton from '../../DefaultButton/DefaultButton';
import ScreenerWidgetDropdown from './ScreenerWidgetDropdown';
import DeepListWidgetDropdown from './DeepListWidgetDropdown';
import { WidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import { ReactComponent as FiltersButton } from '../../../assets/images/icons/WidgetFilterSettings.svg';
import FilterDropdown from '../../FilterScreen/FilterDropdown';
import useWidgetHeader from '../hook/useWidgetHeader';
import useWidgetFiltersModalWorker from '../hook/useWidgetFiltersModalWorker';
import RootStateTypes from '../../../store/RootStateTypes';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import { ReactComponent as IconClose } from '../../../assets/images/icons/alertsIcons/close.svg';
import { ReactComponent as IconPlusWhite } from '../../../assets/images/icons/screener/plus_white.svg';
import { ReactComponent as IconSettings } from '../../../assets/images/icons/settings.svg';
import { DeepListItemData, ScreenItemData, TDashboardItemsData } from '../../../utils/Types';
import SymbolsActionsMenu from './SymbolsActionsMenu';
import ColorGroupDropdownButton from './ColorGroupDropdownButton';
import { findItem } from '../utils/utils';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import { setModalType, setSelectedTab } from '../../../store/watchlist/actions';
import filtersConstants from '../../../constants/filters';
import UseDashboardsDialogs from '../../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import DashboardItemsDialog from '../../DashboardItemsDialog/DashboardItemsDialog';
import { checkRoleUser } from '../../../utils/userHelper';
import {
  SUBSCRIPTION_TYPE,
  TABLE_ITEM_COLORS_SETTINGS,
  TEMPLATE_TYPE,
  MODAL_TITLES,
} from '../../../constants/screener';
import TextWithTooltip from '../../DataPanel/components/TextWithTooltip';
import useGlobalSearch from '../../../utils/hooks/useGlobalSearch';
import DataPanelWidgetDropdown from './DataPanelWidgetDropdown';
import Button from '../../Button/Button';

const WidgetHeader = ({
  removeWidget, widget,
  updateWidget, setColorGroupe,
  openModalHandler, selectedItems, clearSelections,
  symbolCounter, isEditable, togglePanelAdd
}: IWidgetHeader): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    actionOpenDialog,
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();
  const [dropdownStyles, setDropdownStyles] = useState<React.CSSProperties>({});
  const [hidden, setHidden] = useState<boolean>(false);
  const [hiddeNewScreen, setHiddeNewScreen] = useState<boolean>(false);
  const [showAddSymbol, setShowAddSymbol] = useState<boolean>(false);
  const currentModalRef = useRef<HTMLDivElement>(null);
  const colorDropdownRef = useRef<HTMLDivElement>(null);
  const { ref, width } = useResizeDetector();
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const {
    searchHeaderValue,
    resetSearchHandler,
  } = useGlobalSearch();

  const { openFiltersModal } = useWidgetFiltersModalWorker();

  const widgetsFilterVisible = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetsFilterVisible, shallowEqual
  );
  const tabsList = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);
  const selectedSymbols = useSelector((state: RootStateTypes) => state.screenerState.selectedSymbols, shallowEqual);
  const dashboardsNew = useSelector((state: RootStateTypes) => state.dashboardsState.dashboards, shallowEqual);
  const dashboardPresets = useSelector((state: RootStateTypes) => state.dashboardsState.dashboardPresets, shallowEqual);
  const selectedDashboardId = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.selectedDashboardId, shallowEqual
  );

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);
  const selectedDashboardType = ([...dashboardsNew, ...dashboardPresets] as unknown as TDashboardItemsData[]).find(
    (dashboard: TDashboardItemsData) => dashboard.id === selectedDashboardId
  )?.type;

  const [isDropdownActive, setIsDropDownActive] = useState<boolean>(
    selectedDashboardType === TEMPLATE_TYPE.DASHBOARDS_PRESET ? adminRole : true
  );

  const {
    setIsColorDropOpen,
    isColorDropOpen,
    setIsDropDownOpen,
    isDropDownOpen,
    setTitle,
    title,
    selectedItem,
    setSelectedItem,
    setFilters,
  } = useWidgetHeader(widget, updateWidget);

  const handleToggleDropdown = () => {
    if (isDropdownActive) setIsDropDownOpen(!isDropDownOpen);
  };

  const handleSetDropdown = (value: boolean) => {
    if (isDropdownActive) setIsDropDownOpen(value);
  };

  const handleOpenFilters = () => {
    setFilters(false);
    openFiltersModal(widget.id);
  };

  const handleOpenFiltersNew = () => {
    setSelectedItem({} as ScreenItemData);
    setFilters(true);
    handleSetDropdown(false);
    openFiltersModal(widget.id);
  };

  const cleanScreener = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedItem({} as ScreenItemData);
    setTitle(t('screener'));
    updateWidget({ ...widget, screenerId: 0 });
  };

  const handleAddSymbol = () => {
    const tab = findItem(tabsList, widget?.deepListId as number) as DeepListItemData;
    dispatch(setModalType(t('addSymbol')));
    dispatch(setSelectedTab(tab));
  };

  const actionSetCurrentItem = (screen: TDashboardItemsData) => {
    dispatch(setSelectedTab(screen));
  };

  const openDialog = (...values: ActionOpenDialogParams) => {
    actionOpenDialog(...values);
    setIsDropDownOpen(false);
  };

  const handleCreateWatchlist = () => {
    handleSetDropdown(false);
    openModalHandler(MODAL_TITLES.CREATE_DEEPLIST, true);
  };

  const getDropdown = () => {
    switch (widget.type) {
      case WidgetTypes.DEEP_LIST: return (
        <DeepListWidgetDropdown
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          widget={widget}
          dropdownRef={ref}
          updateWidget={updateWidget}
          setDropdownTitle={setTitle}
          handleSetDropdown={handleSetDropdown}
          openModalHandler={openModalHandler}
          openFiltersModal={openFiltersModal}
          actionOpenDialog={openDialog}
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={handleSetDropdown}
          dropdownButtonRef={dropdownButtonRef}
          searchHeaderValue={searchHeaderValue}
          resetSearchHandler={resetSearchHandler}
          handleOpenFiltersNew={handleOpenFiltersNew}
        />
      );
      case WidgetTypes.SCREENER: return (
        <ScreenerWidgetDropdown
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          widget={widget}
          dropdownRef={ref}
          updateWidget={updateWidget}
          setDropdownTitle={setTitle}
          handleSetDropdown={handleSetDropdown}
          openModalHandler={openModalHandler}
          openFiltersModal={openFiltersModal}
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={handleSetDropdown}
          dropdownButtonRef={dropdownButtonRef}
          searchHeaderValue={searchHeaderValue}
          resetSearchHandler={resetSearchHandler}
          handleOpenFiltersNew={handleOpenFiltersNew}
          handleOpenFilters={handleOpenFilters}
        />
      );
      case WidgetTypes.DATA_PANEL: return (
        <DataPanelWidgetDropdown
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          widget={widget}
          dropdownRef={ref}
          updateWidget={updateWidget}
          setDropdownTitle={setTitle}
          handleSetDropdown={handleSetDropdown}
          openModalHandler={openModalHandler}
          openFiltersModal={openFiltersModal}
          isDropDownOpen={isDropDownOpen}
          setIsDropDownOpen={handleSetDropdown}
          dropdownButtonRef={dropdownButtonRef}
          searchHeaderValue={searchHeaderValue}
          resetSearchHandler={resetSearchHandler}
          handleOpenFiltersNew={handleOpenFiltersNew}
          handleOpenFilters={handleOpenFilters}
        />
      );
      default: return null;
    }
  };
  useEffect(() => {
    if (widget?.type === WidgetTypes.DEEP_LIST && widget?.deepListId) {
      const tab = findItem(tabsList, widget.deepListId) as DeepListItemData;
      if (!tab?.parentId && ![UNIVERSE_TYPE].includes(tab?.data?.subType as string)) {
        return setShowAddSymbol(true);
      }
    }
    return setShowAddSymbol(false);
  }, [widget, tabsList]);

  useEffect(() => {
    if (selectedSymbols
      && selectedSymbols?.length === 0
      && selectedItems
      && selectedItems?.length > 0
      && typeof clearSelections === 'function'
    ) {
      clearSelections();
    }
  }, [selectedSymbols]);

  useEffect(() => {
    if (!width) return;
    switch (true) {
      case width > 400:
        setDropdownStyles({
          width: 'auto',
          maxWidth: '200px',
          minWidth: '124px',
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        });
        setHiddeNewScreen(false);
        setHidden(false);
        break;
      case width < 400 && width > 355:
        setDropdownStyles({
          width: 'auto',
          maxWidth: '150px',
          minWidth: '124px',
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        });
        setHiddeNewScreen(false);
        setHidden(false);
        break;
      case width < 355 && width > 300:
        setDropdownStyles({
          width: 'auto',
          maxWidth: '150px',
          minWidth: '124px',
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        });
        setHiddeNewScreen(true);
        setHidden(false);
        break;
      case width < 300 && width > 250:
        setDropdownStyles({
          width: 'auto',
          maxWidth: '124px',
          minWidth: '124px',
          borderBottomRightRadius: '6px',
          borderTopRightRadius: '6px',
        });
        setHiddeNewScreen(true);
        setHidden(false);
        break;
      case width < 250 && width > 180:
        setDropdownStyles({
          width: 'auto',
          maxWidth: '100px',
          minWidth: '100px',
          borderBottomRightRadius: '6px',
          borderTopRightRadius: '6px',
        });
        setHiddeNewScreen(true);
        setHidden(false);
        break;
      case width < 180 && width > 110:
        setDropdownStyles({
          display: 'none',
        });
        setHiddeNewScreen(true);
        setHidden(false);
        break;
      case width < 110:
        setDropdownStyles({
          display: 'none',
        });
        setHiddeNewScreen(true);
        setHidden(true);
        break;
      default:
        break;
    }
  }, [width]);

  useEffect(() => {
    setIsDropDownActive(selectedDashboardType === TEMPLATE_TYPE.DASHBOARDS_PRESET ? adminRole : true);
  }, [selectedDashboardType]);

  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          currentItemHandler={actionSetCurrentItem}
          adminRole={adminRole}
        />
      )}
      <div ref={ref} className={styles.dashboardWidgetHeader}>
        <div className={styles.dashboardWidgetHeaderLeftBlock}>
          <div className={`${styles.dndHeader} handle-grid-drag`}>
            {isEditable && <DndControl />}
          </div>
          {selectedItems && selectedItems?.length > 0 && (
            <SymbolsActionsMenu
              isDeepList={widget.type === WidgetTypes.DEEP_LIST}
              selectedItems={selectedItems}
              selectedFromWidget={widget?.deepListId}
            />
          )}
          <DefaultButton
            className={`${styles.dashboardWidgetHeaderLeftBlockButton}
            ${widget?.type === WidgetTypes.SCREENER && styles.screenerButton}
            ${widget?.type === WidgetTypes.DATA_PANEL && styles.dataPanelButton}
            ${isDropDownOpen && styles.active}`}
            handleClick={handleToggleDropdown}
            componentStyles={dropdownStyles}
            buttonRef={dropdownButtonRef}
          >
            <>
              <TextWithTooltip className={styles.titleText} title={title} textTooltip={title} />
              {widget?.type === WidgetTypes.SCREENER && !!widget?.screenerId && isDropdownActive && (
                <IconClose className={styles.cleanIcon} onClick={(e) => cleanScreener(e)} />
              )}
              {isDropdownActive && (<ChevronBlue />)}
            </>
          </DefaultButton>
          {
            widget?.type !== WidgetTypes.DATA_PANEL && (
              <DefaultButton
                className={styles.screenAddButton}
                handleClick={widget?.type === WidgetTypes.SCREENER ? handleOpenFiltersNew : handleCreateWatchlist}
                componentStyles={hiddeNewScreen ? { display: 'none' } : {}}
              >
                {widget?.type === WidgetTypes.SCREENER ? t('addNewScreen') : t('newWatchlistFirst')}
              </DefaultButton>
            )
          }
          {widget?.type === WidgetTypes.SCREENER && selectedItem && selectedItem?.id && isDropdownActive && (
            <DefaultButton
              className={styles.dashboardWidgetHeaderLeftBlockButtonSettings}
              handleClick={handleOpenFilters}
              componentStyles={hidden ? { display: 'none' } : {}}
            >
              <FiltersButton color={TABLE_ITEM_COLORS_SETTINGS.DEFAULT_WHITE} />
            </DefaultButton>
          )}
          {(widget?.type === WidgetTypes.DEEP_LIST && showAddSymbol
            && !(!adminRole && selectedItem?.type === TEMPLATE_TYPE.WATCHLIST_PRESETS)) && (
            <DefaultButton
              className={styles.deepListAddButton}
              handleClick={handleAddSymbol}
              componentStyles={hiddeNewScreen ? { display: 'none' } : {}}
            >
              <IconPlusWhite />
            </DefaultButton>
          )}
        </div>
        <div
          ref={colorDropdownRef}
          className={styles.dashboardWidgetHeaderRightBlock}
        >
          <ColorGroupDropdownButton
            toggleColorDrop={setIsColorDropOpen}
            isColorDropOpen={isColorDropOpen}
            widget={widget}
            colorDropdownRef={colorDropdownRef}
            handleSetColorGroupe={setColorGroupe}
            componentStyle={hidden ? { display: 'none' } : {}}
          />
          {(selectedItem?.type === WidgetTypes.DATA_PANEL
            || (adminRole && selectedItem?.type === TEMPLATE_TYPE.DATA_PANEL_PRESET)) && (
            <Button
              buttonClass={styles.settingsButton}
              handleClick={togglePanelAdd}
            >
              <IconSettings />
            </Button>
          )}
          {isEditable && <CloseIcon className={styles.butClose} onClick={() => removeWidget(widget.id)} /> }
        </div>
        <Portal>
          <FilterDropdown
            headerFilter={selectedItem && Object.keys(selectedItem).length
              ? filtersConstants.filters
              : filtersConstants.newScreen}
            isVisibleFilters={widgetsFilterVisible[widget.id]}
            openModalHandler={openModalHandler}
            setIsVisibleFilters={() => openFiltersModal(widget.id)}
            currentModalRef={currentModalRef}
            widgetSymbolCounter={symbolCounter as number}
            widgetId={widget.id}
          />
        </Portal>
      </div>
      {getDropdown()}
    </>

  );
};

export default WidgetHeader;
