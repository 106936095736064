import React, { useState, useEffect, BaseSyntheticEvent } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styles from '../sass/DashboardItemDropdown.module.scss';
import DashboardItem from './DashboardItem';
import { TDashboardItemsData } from '../../../utils/Types';
import { getPositionForSubitems } from '../utils/utils';
import { DasboardsItemsDropdownTypes } from '../types/constants';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';

interface ISubitemsMenu {
  anchorElement: null | HTMLElement,
  items: TDashboardItemsData[]
  itemList: TDashboardItemsData[]
  theme: string,
  selectedItem: TDashboardItemsData,
  setSelectedItem: (itemId: string | TDashboardItemsData) => void,
  actionDuplicate: (item: TDashboardItemsData) => void,
  actionShare: (item: TDashboardItemsData) => void,
  actionDelete: (item: TDashboardItemsData) => void,
  actionEdit: (item: TDashboardItemsData) => void,
  actionMove: (item: TDashboardItemsData) => void,
  actionFavorite: (item: TDashboardItemsData) => void,
  actionRemove: (item: TDashboardItemsData) => void
  selectedTab: DasboardsItemsDropdownTypes;
  adminRole: boolean;
  subitemsId: number;
  disabledDrag: boolean;
  handleCloseSubitem: (e: BaseSyntheticEvent) => void;
  itemType: DialogItemDataTypes
  setDragStart: (dragStart: boolean) => void
}

const SubitemsMenu = ({
  anchorElement,
  items,
  theme,
  selectedItem,
  setSelectedItem,
  actionDuplicate,
  actionShare,
  actionDelete,
  actionEdit,
  actionMove,
  actionFavorite,
  actionRemove,
  selectedTab,
  adminRole,
  subitemsId,
  disabledDrag,
  handleCloseSubitem,
  itemType,
  setDragStart,
  itemList
}: ISubitemsMenu): React.ReactElement | null => {
  const [positionState, setPositionState] = useState<React.CSSProperties>({});
  const isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));

  useEffect(() => {
    if (anchorElement) {
      const position = getPositionForSubitems(anchorElement as HTMLElement,
        items?.length, itemList);

      setPositionState(position);
    }
  }, [anchorElement, items]);

  if (Object.keys(positionState).length) {
    return (
      <Droppable droppableId={`${subitemsId}__subitems`}>
        {(provided) => (
          <div
            onMouseLeave={handleCloseSubitem}
            style={positionState}
            className={styles.subItemMenu}
            ref={provided.innerRef}
            {...provided.droppableProps}  // eslint-disable-line
          >
            <div
              className={styles.itemList}
              style={{ width: '100%', minHeight: `${items?.length * 52}px` }}
            >
              {items.map((subitem, index) => (
                <Draggable
                  key={subitem?.id}
                  draggableId={`${subitem?.id}`}
                  index={index}
                  isDragDisabled={disabledDrag}
                >
                  {(dragProvided) => (
                    <DashboardItem
                      provided={dragProvided}
                      key={subitem.id}
                      theme={theme}
                      item={subitem}
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                      actionDuplicate={actionDuplicate}
                      actionShare={actionShare}
                      actionDelete={actionDelete}
                      actionEdit={actionEdit}
                      isTouchDevice={isTouchDevice}
                      actionMove={actionMove}
                      actionFavorite={actionFavorite}
                      actionRemove={actionRemove}
                      selectedTab={selectedTab}
                      adminRole={adminRole}
                      singleItem={false}
                      disableDND={disabledDrag}
                      itemType={itemType}
                      setDragStart={setDragStart}
                    />
                  )}
                </Draggable>
              ))}
            </div>
          </div>
        )}
      </Droppable>
    );
  } return null;
};

export default SubitemsMenu;
