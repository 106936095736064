export default {
  dashboardAddWidget: 'Add Apps',
  chartName: 'Chart',
  screenerName: 'Screener',
  deepListName: 'Watchlist',
  dashboards: 'Dashboard',
  colorGroupeLabel: 'Group Selection',
  newDashboard: 'New Dashboard',
  addApp: 'Add App',
  createDashboardHeader: 'Create New Dashboard',
  createDashboardDescription: 'Dashboard name',
  createDashboardPlaceholder: 'Type Dashboard name',
  renameDashboard: 'Rename Dashboard',
  dashboardName: 'Dashboard name',
  areYouSureDashboard: 'Are you sure you want to delete Dashboard:',
  successCreateDashboard: 'New dashboard was created successfully',
  successDeleteDashboard: 'Dashboard was deleted successfully',
  updatedDashboard: 'Dashboard updated',
  statsChartName: 'Stats Table',
  selectedWidgetFooter: '{{activeSymbolIndex}} of {{currentCount}} results ({{selectedCount}} selected)',
  myDashboard: 'My Dashboard',
  createDashboardFirst: 'Get started by creating your first dashboard!',
  createDashboard: 'Create Dashboard',
  saveDashboard: 'Save Dashboard',
  dasboardsEmptyState: 'Dashboards list is empty',
  positionSizeName: 'Position Size Calculator',
  searchByDashboard: 'Search by Dashboard...',
  performanceChartName: 'Performance Chart',
  heatMapName: 'Heat Map',
  bubbleChartName: 'Bubble Chart',
  dataPanelName: 'Data Panel',
  savedDashboards: 'Saved Dashboards',
  presetsDashboards: 'Dashboard Presets',
  myDashboards: 'My Dashboards',
  successCreatedDashboard: 'New dashboard successfully added',
  searchByDBlaceholder: 'Search by dashboards...',
  createDashboardPreset: 'Create Dashboard Preset',
  successDuplicateDashboard: 'Dashboard was duplicated successfully',

};
