import React, { useEffect, useRef } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './sass/DashboardComponents.module.scss';
import DashboardGrid from './DashboardGrid';
import DefaultButton from '../DefaultButton/DefaultButton';
import { IDashboardWrapper } from './types/DashboardComponentsInterfaces';
import { setWidgetMenuOpen } from '../../store/dashboards/slice';
import { ReactComponent as IconPlus } from '../../assets/images/icons/screener/plus_white.svg';
import { ReactComponent as IconEmptyState } from '../../assets/images/icons/DashboardEmpyState.svg';
import RootStateTypes from '../../store/RootStateTypes';
import { DashboardItemsDialogActionTypes } from '../DashboardItemsDialog/types/constants';
import { DasboardsItemsDropdownTypes, DialogItemEntityType } from '../DashboardsItemsDropdown/types/constants';
import DashboardItemsDialog from '../DashboardItemsDialog/DashboardItemsDialog';
import UseDashboardsDialogs from '../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import { checkRoleUser } from '../../utils/userHelper';
import { SUBSCRIPTION_TYPE } from '../../constants/screener';

const DashboardsWraper = ({
  dashboard, updateWidget,
  activeSymbolIndexValue, setSearch, resetDashboardSearch,
  isUpdatedAllData, emptyState, globalSearchKeyHandler, setEnableDrag
}: IDashboardWrapper): React.ReactElement => {
  const gridRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    actionOpenDialog,
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();
  const widgetMenuOpen = useSelector((state: RootStateTypes) => state.dashboardsState.widgetMenuOpen, shallowEqual);
  const dataPanelEditMenuOpen = useSelector(
    (state: RootStateTypes) => state.dashboardsState.dataPanelEditMenuOpen, shallowEqual
  );
  const isDashboardListLoading = useSelector(
    (state: RootStateTypes) => state.dashboardsState.isDashboardListLoading, shallowEqual
  );

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const openWidgetMenu = () => {
    dispatch(setWidgetMenuOpen(true));
  };

  const onAddDashboard = () => {
    actionOpenDialog(DashboardItemsDialogActionTypes.ADD, 4,
      DialogItemEntityType.ITEM, DasboardsItemsDropdownTypes.SAVED);
  };

  useEffect(() => () => {
    gridRef.current = null;
  }, []);

  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          currentItemHandler={() => {}}
          adminRole={adminRole}
        />
      )}
      <div
        ref={gridRef}
        className={`${styles.dashboardsGridWrapper} ${widgetMenuOpen && styles.cutted} 
      ${dataPanelEditMenuOpen && styles.dataPanelCutted}`}
      >
        {(dashboard && isUpdatedAllData) && (
          <DashboardGrid
            updateWidget={updateWidget}
            dashboard={dashboard}
            parrentRef={gridRef}
            resetDashboardSearch={resetDashboardSearch}
            activeSymbolIndexValue={activeSymbolIndexValue}
            setSearch={setSearch}
            globalSearchKeyHandler={globalSearchKeyHandler}
            setEnableDrag={setEnableDrag}
          />
        )}
        {dashboard?.data?.widgets.length === 0 && !widgetMenuOpen && (
          <div className={styles.emptywidgetButton}>
            <DefaultButton handleClick={openWidgetMenu} className={styles.buttonAddBig}>
              <IconPlus />
            </DefaultButton>
            <span className={styles.addText}>
              {t('dashboardAddWidget')}
            </span>
          </div>
        )}
        {!dashboard && emptyState && !isDashboardListLoading && (
          <div className={styles.emptywidgetButtonBig}>
            <div className={styles.emptyStateIcon}>
              <IconEmptyState />
            </div>
            <span className={styles.addTextBig}>
              {t('createDashboardFirst')}
            </span>
            <DefaultButton handleClick={onAddDashboard} className={styles.buttonAddDash}>
              <IconPlus />
              {t('createDashboard')}
            </DefaultButton>
          </div>
        )}
      </div>
    </>

  );
};

export default DashboardsWraper;
