import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import IndustryRankDropDown from './IndustryRankDropdown';
import styles from '../sass/DataPanel.module.scss';
import TextWithTooltip from './TextWithTooltip';
import DataPanelDynamicContentItem from './DataPanelDynamicContentItem';
import IndustryDropDown from './IndustryDropDown';
import { ReactComponent as IconStroke } from '../../../assets/images/icons/screener/color_stroke.svg';
import {
  groupIndex, groupRankIndexes,
  industryGroupIndexes,
  industryIndex, industryRankIndexes,
  sectorIndex, sectorRankIndexes,
  subIndustryIndex, subIndustryRankIndexes
} from '../../../constants/dataPanel';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import {
  DATA_PANEL_SETTINGS,
  IS_GROUP_OPEN,
  IS_INDUSTRY_OPEN,
  IS_SECTOR_OPEN,
  IS_SUB_INDUSTRY_OPEN
} from '../../../constants/storage';

const DataPanelContentItem = ({
  dataPoint, screenerId, staticData, symbolIndex, getPointData,
  sectorIndustryWidgetHandler, widget, changeSymbolForGroup
}) => {
  const { updateStoreHandler } = useLastStateUpdateStore();
  const industryDataRows = useSelector((state) => state.tableDataState.industryDataRows, shallowEqual);
  const dataPanelSettings = useSelector(
    (state) => state.accountState.userSettings.dataPanelSettings, shallowEqual
  );
  const industryDataColumns = useSelector((state) => state.tableDataState.industryDataColumns, shallowEqual);
  const sectorDataRows = useSelector((state) => state.tableDataState.sectorDataRows, shallowEqual);
  const sectorDataColumns = useSelector((state) => state.tableDataState.sectorDataColumns, shallowEqual);
  const groupDataRows = useSelector((state) => state.tableDataState.groupDataRows, shallowEqual);
  const groupDataColumns = useSelector((state) => state.tableDataState.groupDataColumns, shallowEqual);
  const subIndustryDataRows = useSelector((state) => state.tableDataState.subIndustryDataRows, shallowEqual);
  const subIndustryDataColumns = useSelector((state) => state.tableDataState.subIndustryDataColumns, shallowEqual);
  const industryScreenerId = useSelector(
    (state) => state.tableDataState.industryScreenerId, shallowEqual
  );
  const sectorScreenerId = useSelector(
    (state) => state.tableDataState.sectorScreenerId, shallowEqual
  );
  const groupScreenerId = useSelector(
    (state) => state.tableDataState.groupScreenerId, shallowEqual
  );
  const subIndustryScreenerId = useSelector(
    (state) => state.tableDataState.subIndustryScreenerId, shallowEqual
  );
  const industryRankData = useSelector((state) => state.tableDataState.industryRankData, shallowEqual);
  const initialFilterList = useSelector((state) => state.screenerState.initialFilterList, shallowEqual);

  const [currentData, setCurrentData] = useState(null);

  const isIndustry = industryGroupIndexes.includes(dataPoint?.value);
  const isIndustryRank = [...industryRankIndexes, ...subIndustryRankIndexes,
    ...sectorRankIndexes, ...groupRankIndexes].includes(dataPoint?.value);

  const getIndustryRankData = () => {
    const industryRankDataArray = [];
    let dataPointIndex = null;
    if (industryRankData?.industries && Object.keys(industryRankData?.industries)?.length) {
      const industriesNamesData = initialFilterList.find((list) => list?.name === 'Sector & Industry')?.items;
      if (industryRankIndexes.includes(dataPoint?.value)) {
        const industryNames = industriesNamesData?.find((industry) => industry?.id === industryIndex)?.fVal;
        Object.values(industryNames)?.forEach((industryName) => {
          if (industryRankData?.industries[industryName?.value]
            && industryRankData?.industries[industryName?.value][dataPoint?.value] !== 'N/A'
            && industryName?.title !== 'N/A') {
            industryRankDataArray.push([industryName?.title || '-',
              industryRankData?.industries[industryName?.value][dataPoint?.value] || 10000,
              industryRankData?.industries[industryName?.value]?.symbolsCount] || '-');
          }
        });
        dataPointIndex = industryIndex;
      }
      if (subIndustryRankIndexes.includes(dataPoint?.value)) {
        const subIndustryNames = industriesNamesData?.find((industry) => industry?.id === subIndustryIndex)?.fVal;
        Object.values(subIndustryNames)?.forEach((subIndustryName) => {
          if (industryRankData?.subIndustries[subIndustryName?.value]
            && industryRankData?.subIndustries[subIndustryName?.value][dataPoint?.value] !== 'N/A'
          && subIndustryName?.title !== 'N/A') {
            industryRankDataArray.push([subIndustryName?.title || '-',
              industryRankData?.subIndustries[subIndustryName?.value][dataPoint?.value] || 10000,
              industryRankData?.subIndustries[subIndustryName?.value]?.symbolsCount] || '-');
          }
        });
        dataPointIndex = subIndustryIndex;
      }
      if (sectorRankIndexes.includes(dataPoint?.value)) {
        const sectorNames = industriesNamesData?.find((industry) => industry?.id === sectorIndex)?.fVal;
        Object.values(sectorNames)?.forEach((sectorName) => {
          if (industryRankData?.sectors[sectorName?.value]
          && industryRankData?.sectors[sectorName?.value][dataPoint?.value]) {
            industryRankDataArray.push([sectorName?.title,
              industryRankData?.sectors[sectorName?.value][dataPoint?.value],
              industryRankData?.sectors[sectorName?.value]?.symbolsCount]);
          }
        });
        dataPointIndex = sectorIndex;
      }
      if (groupRankIndexes.includes(dataPoint?.value)) {
        const groupNames = industriesNamesData?.find((industry) => industry?.id === groupIndex)?.fVal;
        Object.values(groupNames)?.forEach((groupName) => {
          if (industryRankData?.groups[groupName?.value]
          && industryRankData?.groups[groupName?.value][dataPoint?.value]) {
            industryRankDataArray.push([groupName?.title,
              industryRankData?.groups[groupName?.value][dataPoint?.value],
              industryRankData?.groups[groupName?.value]?.symbolsCount]);
          }
        });
        dataPointIndex = groupIndex;
      }
    }
    return [industryRankDataArray.sort((a, b) => a[1] - b[1]), dataPointIndex];
  };

  const getData = (dataPointValue) => {
    let data;
    switch (dataPointValue) {
      case groupIndex: {
        data = [
          groupDataRows, groupDataColumns, groupScreenerId, IS_GROUP_OPEN,
          dataPanelSettings.isGroupOpen
        ];
        break;
      }
      case industryIndex: {
        data = [
          industryDataRows, industryDataColumns, industryScreenerId,
          IS_INDUSTRY_OPEN, dataPanelSettings.isIndustryOpen
        ];
        break;
      }
      case sectorIndex: {
        data = [
          sectorDataRows, sectorDataColumns, sectorScreenerId, IS_SECTOR_OPEN,
          dataPanelSettings.isSectorOpen
        ];
        break;
      }
      case subIndustryIndex: {
        data = [
          subIndustryDataRows, subIndustryDataColumns, subIndustryScreenerId, IS_SUB_INDUSTRY_OPEN,
          dataPanelSettings.isSubIndustryOpen
        ];
        break;
      }
      default: {
        data = [
          null, null, null, null, null
        ];
        break;
      }
    }
    return data;
  };

  const [dataRows, dataColumns, dataScreenerId, stringValueIsOpen, isOpen] = getData(dataPoint?.value);

  const setOpenDropdown = () => {
    if (isIndustry) {
      updateStoreHandler(DATA_PANEL_SETTINGS, {
        ...dataPanelSettings,
        [stringValueIsOpen]: !isOpen
      });
    } else if (isIndustryRank) {
      updateStoreHandler(DATA_PANEL_SETTINGS, {
        ...dataPanelSettings,
        [dataPoint?.value]: !dataPanelSettings[dataPoint?.value]
      });
    }
  };

  useEffect(() => {
    setCurrentData(staticData);
  }, [symbolIndex, staticData]);

  const [industryRankDataColumns, dataPointIndex] = getIndustryRankData();

  return (
    <div
      className={`${styles.dataPanelItemCard} ${(isIndustry || isIndustryRank) && styles.cursorPointer}`}
    >
      <div
        className={styles.dataPanelItemEdit}
        aria-hidden
        onClick={setOpenDropdown}
      >
        <div className={`${styles.dataPanelItemEditTitle} ${styles.short}`}>
          <TextWithTooltip title={dataPoint?.label} widget={widget} />
        </div>
        {(isIndustry || isIndustryRank) ? (
          <div className={styles.dataPanelItemEditContentItem}>
            <DataPanelDynamicContentItem
              dataPoint={dataPoint}
              screenerId={screenerId}
              symbolIndex={symbolIndex}
              currentData={currentData}
            />
            {isIndustryRank && (
              <div className={styles.dataPanelEditContentRank}>
                {industryRankDataColumns.length}
              </div>
            )}
            <IconStroke className={styles[isOpen ? 'activeStroke' : 'defaultStroke']} />
          </div>
        ) : (
          <DataPanelDynamicContentItem
            dataPoint={dataPoint}
            screenerId={screenerId}
            symbolIndex={symbolIndex}
            currentData={currentData}
          />
        )}
      </div>
      {
        isOpen && (
          <>
            <div className={styles.divider} />
            <IndustryDropDown
              dataColumns={dataColumns}
              dataRows={dataRows}
              screenerId={dataScreenerId}
              getPointData={getPointData}
              changeSymbolForGroup={changeSymbolForGroup}
              widget={widget}
            />
          </>
        )
       }
      {
        dataPanelSettings[dataPoint?.value] && (
          <>
            <div className={styles.divider} />
            <IndustryRankDropDown
              dataRows={industryRankDataColumns}
              sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
              dataPointIndex={dataPointIndex}
              symbolIndex={symbolIndex}
            />
          </>
        )
      }
    </div>
  );
};

DataPanelContentItem.propTypes = {
  dataPoint: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
  }),
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  staticData: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  symbolIndex: PropTypes.number.isRequired,
  getPointData: PropTypes.func,
  widget: PropTypes.shape({
    id: PropTypes.string,
  }),
  changeSymbolForGroup: PropTypes.func,
  sectorIndustryWidgetHandler: PropTypes.func,
};

DataPanelContentItem.defaultProps = {
  screenerId: null,
  staticData: null,
  dataPoint: null,
  widget: {},
  getPointData: () => null,
  changeSymbolForGroup: () => null,
  sectorIndustryWidgetHandler: () => null
};

export default DataPanelContentItem;
