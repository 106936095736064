import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';
import { InfoTooltipStyles, InfoTooltipDarkStyles } from '../../Screener/ScreenerTooltipStyles';
import { useTheme } from '../../../utils/hooks/useTheme';
import ThemeVariants from '../../../constants/theme';
import urlPattern from '../../../constants/regex';

import newStyles from '../sass/DataPanel.module.scss';

const TextWithTooltip = ({
  className,
  title,
  textTooltip,
  placement,
  isStatic,
  tooltipStyles,
  withoutArrow,
  widget
}) => {
  const titleRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const { theme } = useTheme();

  const isMobile = ((navigator.msMaxTouchPoints > 0) || (navigator.maxTouchPoints > 0));
  let timeout = null;

  const isValidUrl = (urlString) => {
    const url = new RegExp(urlPattern);
    return !!url.test(urlString);
  };

  const toggleIsShowTooltip = (isOpen) => {
    if (showTooltip) {
      setIsShowTooltip(isOpen);
    }
  };

  const tooltipModeStyles = theme === ThemeVariants.DARK_MODE ? InfoTooltipDarkStyles : InfoTooltipStyles;
  const styles = Object.keys(tooltipStyles).length ? tooltipStyles : tooltipModeStyles;

  useEffect(() => {
    if (titleRef?.current && !isStatic) {
      const compare = titleRef.current.scrollWidth > titleRef.current.clientWidth;
      setShowTooltip(compare);
    }
    if (isStatic) {
      setShowTooltip(true);
    }
  }, [titleRef?.current, title, isStatic]);

  const closeTooltip = () => {
    setIsShowTooltip(false);
  };
  useEffect(() => {
    if (isShowTooltip && isMobile) {
      clearTimeout(timeout);
      timeout = setTimeout(() => toggleIsShowTooltip(false), 0);
    }

    return () => clearTimeout(timeout);
  }, [isShowTooltip]);

  useEffect(() => {
    const scrollContainer = document.getElementById(`dataPanelContent-${widget?.id}`);
    scrollContainer?.addEventListener('scroll', closeTooltip);

    return () => {
      scrollContainer?.removeEventListener('scroll', closeTooltip);
    };
  }, []);

  return (
    <Tooltip
      aria-label="add"
      arrow={!withoutArrow}
      disableHoverListener={!showTooltip}
      open={isShowTooltip}
      title={showTooltip && (textTooltip || title)}
      componentsProps={styles}
      placement={placement || 'bottom'}
      disableFocusListener
      disableTouchListener
      leaveDelay={0}
      TransitionProps={{ timeout: 0 }}
    >
      <span
        ref={titleRef}
        className={`${className} ${isValidUrl(title) && newStyles.urlHover}`}
        onMouseEnter={() => toggleIsShowTooltip(true)}
        onMouseLeave={() => {
          const timeoutSet = setTimeout(() => {
            closeTooltip();
            clearTimeout(timeoutSet);
          }, 0);
        }}
        onClick={() => !isShowTooltip && toggleIsShowTooltip(true)}
        aria-hidden
      >
        {title}
      </span>
    </Tooltip>
  );
};

TextWithTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  textTooltip: PropTypes.string,
  placement: PropTypes.string,
  isStatic: PropTypes.bool,
  tooltipStyles: PropTypes.shape({}),
  widget: PropTypes.shape({
    id: PropTypes.number
  }),
  withoutArrow: PropTypes.bool,
};

TextWithTooltip.defaultProps = {
  className: '',
  textTooltip: '',
  placement: 'bottom',
  isStatic: false,
  tooltipStyles: {},
  withoutArrow: false,
  widget: {},
};

export default TextWithTooltip;
