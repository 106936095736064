export enum IndustryGroupNames {
  INDUSTRY,
  SECTOR,
  GROUP,
  SUBINDUSTRY
}

export const industryColumns = [
  218, 1741, 1995
];

export const groupIndex = 29;

export const industryIndex = 21;

export const sectorIndex = 20;

export const subIndustryIndex = 30;

export const priceChangeToday = 218;

export const industryGroupIndexes = [
  20, 21, 29, 30
];

export const industryRankIndexes = [
  2054, 2055, 1937, 1938, 1939, 2056, 1940
];

export const subIndustryRankIndexes = [
  2071, 2072, 2073, 2074, 2075, 2076, 2077
];

export const sectorRankIndexes = [
  2057, 2058, 2059, 2060, 2061, 2062, 2063
];

export const groupRankIndexes = [
  2064, 2065, 2066, 2067, 2068, 2069, 2070
];

export const industryDataRowsNames = {
  [IndustryGroupNames.INDUSTRY]: 'industryDataRows',
  [IndustryGroupNames.SECTOR]: 'sectorDataRows',
  [IndustryGroupNames.GROUP]: 'groupDataRows',
  [IndustryGroupNames.SUBINDUSTRY]: 'subIndustryDataRows'
};

export const industryDataColumnsNames = {
  [IndustryGroupNames.INDUSTRY]: 'industryDataColumns',
  [IndustryGroupNames.SECTOR]: 'sectorDataColumns',
  [IndustryGroupNames.GROUP]: 'groupDataColumns',
  [IndustryGroupNames.SUBINDUSTRY]: 'subIndustryDataColumns'
};

export const industryDataScreenerIds = {
  [IndustryGroupNames.INDUSTRY]: 'industryScreenerId',
  [IndustryGroupNames.SECTOR]: 'sectorScreenerId',
  [IndustryGroupNames.GROUP]: 'groupScreenerId',
  [IndustryGroupNames.SUBINDUSTRY]: 'subIndustryScreenerId'
};

export const industryColumnTitleNames = {
  [industryIndex]: 'Industry Name',
  [sectorIndex]: 'Sector Name',
  [groupIndex]: 'Group Name',
  [subIndustryIndex]: 'Sub-Industry Name'
};
