import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { IWidgetHeadScreenDropdown } from '../types/DashboardComponentsInterfaces';
import {
  MODAL_TITLES,
} from '../../../constants/screener';
import { DataPanelItemData, TDashboardItemsData } from '../../../utils/Types';
import RootStateTypes from '../../../store/RootStateTypes';
import DashboardsItemDropdown from '../../DashboardsItemsDropdown/DasboardsItemDropdown';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';
import { ReactComponent as DataPanelIcon } from '../../../assets/images/icons/screener/dataPanel.svg';
import { setSelectedDataPanel } from '../../../store/newScreen/actions';
import useWorkerItems from '../../../utils/hooks/useWorkerItems';
import styles from '../../DataPanel/sass/DataPanel.module.scss';
import { SELECTED_DATA_PANEL_ID } from '../../../constants/storage';
import { userPanel } from '../../../constants/account';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { DasboardsItemsDropdownTypes } from '../../DashboardsItemsDropdown/types/constants';

const DataPanelWidgetDropdown = ({
  selectedItem, widget, updateWidget, setDropdownTitle,
  setSelectedItem, openModalHandler,
  isDropDownOpen, setIsDropDownOpen,
  dropdownButtonRef
}: IWidgetHeadScreenDropdown): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const dataPanelsItems = useSelector(
    (state: RootStateTypes) => state.newScreenState.dataPanelsItems, shallowEqual
  );
  const dataPanels = useSelector(
    (state: RootStateTypes) => state.newScreenState.dataPanels, shallowEqual
  );
  const dataPanelsItemsPresets = useSelector(
    (state: RootStateTypes) => state.newScreenState.dataPanelsItemsPresets, shallowEqual
  );

  const {
    getDataPanel,
    getDataPanelPreset,
    getDataPanelPresetFolders,
    getDataPanelFolders
  } = useWorkerItems();

  const actionUpdateState = () => {
    getDataPanel();
    getDataPanelPreset();
    getDataPanelPresetFolders();
    getDataPanelFolders();
  };

  const setCurrentDataPanelWidgetHandler = (dataPanel: TDashboardItemsData) => {
    setSelectedItem(dataPanel);
    setDropdownTitle(dataPanel?.title);
    updateWidget({ ...widget, dataPanelId: dataPanel.id });
  };

  const actionSetCurrentItem = (item: string | TDashboardItemsData) => {
    setIsDropDownOpen(false);
    if (item && typeof item !== 'string') {
      setCurrentDataPanelWidgetHandler(item);
    } else {
      const defaultDataPanel = dataPanels.find((dataPanel: DataPanelItemData) => dataPanel?.title === userPanel)
        || dataPanels[0];
      setCurrentDataPanelWidgetHandler(defaultDataPanel);
    }
  };

  const actionEdit = (item: TDashboardItemsData) => {
    dispatch(setSelectedDataPanel(item));
    updateStoreHandler(SELECTED_DATA_PANEL_ID, item?.id);
  };

  const actionCreateNew = (selectedTab: DasboardsItemsDropdownTypes) => {
    if (selectedTab) {
      return openModalHandler(MODAL_TITLES.CREATE_DATA_PANEL_PRESET, true);
    }
    return openModalHandler(MODAL_TITLES.CREATE_DATA_PANEL, true);
  };

  return (
    <DashboardsItemDropdown
      itemType={DialogItemDataTypes.DATA_PANEL}
      savedItems={dataPanelsItems}
      presetItems={dataPanelsItemsPresets}
      isOpen={isDropDownOpen}
      setIsOpen={setIsDropDownOpen}
      parrentRef={dropdownButtonRef}
      labelPreset={t('presetsDataPanels')}
      labelSaved={t('savedDataPanels')}
      labelAdd={t('dataPanel')}
      labelDescription={t('dataPanels')}
      iconAddMenu={<DataPanelIcon className={styles.iconDataPanel} />}
      actionCreateNewCallback={actionCreateNew}
      actionEditCallback={actionEdit}
      selectedItem={selectedItem}
      currentItemHandler={actionSetCurrentItem}
      topMargin={7}
      updateAfterDragActions={actionUpdateState}
    />
  );
};

export default DataPanelWidgetDropdown;
