import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';

import { DATA_PANEL_ACTION_TYPES } from '../../DataPanel/dataPanelConstants';
import useDataPanelsItems from '../../DataPanel/hooks/useDataPanelItems';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { DATA_PANEL_GRID_TYPE } from '../../../constants/tvWidgetOptions';
import RootStateTypes from '../../../store/RootStateTypes';
import { userPanel } from '../../../constants/account';
import { DataPanelItemData } from '../../../utils/Types';
import { setDataPanelEditMenuOpen, setWidgetMenuOpen } from '../../../store/dashboards/slice';

const useDataPanelWidget: (widget: Widget) => {
  setEditMode: (editModeNew: boolean) => void;
  addPanelOpen: boolean;
  setDataPanelGridType: (type: string) => void;
  editMode: boolean;
  togglePanelAdd: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentDataPanels: any;
  checkDefault: () => boolean
} = (widget) => {
  const { updateStoreHandler } = useLastStateUpdateStore();
  const { saveDataPanels } = useDataPanelsItems(widget);
  const dispatch = useDispatch();
  const [addPanelOpen, setAddPanelOpen] = useState(false);
  const [currentDataPanels, setCurrentDataPanels] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentHistoryDataPanels, setCurrentHistoryDataPanels] = useState(null);
  const [notClean, setNotClean] = useState(false);

  const dataPanels = useSelector(
    (state: RootStateTypes) => state.newScreenState?.dataPanels, shallowEqual
  );
  const dataPanelsPresets = useSelector(
    (state: RootStateTypes) => state.newScreenState?.dataPanelsPresets, shallowEqual
  );

  const openPanelDataAdd = useSelector((state: RootStateTypes) => state.accountState?.openPanelDataAdd, shallowEqual);
  const [selectedDataPanel, setSelectedDataPanel] = useState(
    [...dataPanels, ...dataPanelsPresets].find((dataPanel) => dataPanel?.id === widget?.dataPanelId)
  );

  const setDataPanelGridType = (type: string) => {
    updateStoreHandler(DATA_PANEL_GRID_TYPE, type);
  };

  const togglePanelAdd = () => {
    dispatch(setWidgetMenuOpen(false));
    setEditMode(!editMode);
    dispatch(setDataPanelEditMenuOpen(!editMode));
  };

  const setEditModeNew = (editModeNew: boolean) => {
    dispatch(setWidgetMenuOpen(false));
    setEditMode(editModeNew);
    dispatch(setDataPanelEditMenuOpen(editModeNew));
  };

  const checkDefault = () => {
    const defaultDataPanel = dataPanels?.find((dataPanel: DataPanelItemData) => dataPanel?.isDefault)
      || dataPanels?.find((dataPanel: DataPanelItemData) => dataPanel?.title === 'Default');
    if (!selectedDataPanel?.data) return true;
    return JSON.stringify(selectedDataPanel?.data) === JSON.stringify(defaultDataPanel?.data);
  };

  const checkDiff = () => {
    if (notClean && !currentHistoryDataPanels) return false;
    return JSON.stringify(selectedDataPanel?.data) !== JSON.stringify(currentHistoryDataPanels);
  };

  useEffect(() => {
    setSelectedDataPanel([...dataPanels, ...dataPanelsPresets].find(
      (dataPanel) => dataPanel?.id === widget?.dataPanelId
    ));
  }, [widget?.dataPanelId, dataPanels, [...dataPanels, ...dataPanelsPresets].find(
    (dataPanel) => dataPanel?.id === widget?.dataPanelId
  )?.data.leftColumn?.length,
  [...dataPanels, ...dataPanelsPresets].find(
    (dataPanel) => dataPanel?.id === widget?.dataPanelId
  )?.data.rightColumn?.lengthdataPanelsPresets]);

  useEffect(() => {
    if (!openPanelDataAdd && addPanelOpen && !checkDefault() && checkDiff()) {
      saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
      setCurrentHistoryDataPanels({ ...selectedDataPanel?.data });
      setNotClean(false);
    }
    setAddPanelOpen(openPanelDataAdd);
  }, [openPanelDataAdd]);

  useEffect(() => {
    if (selectedDataPanel) {
      setNotClean(!currentDataPanels);
      setCurrentDataPanels(selectedDataPanel?.data);
    } else {
      const defaultDataPanel = dataPanels?.find((dataPanel: DataPanelItemData) => dataPanel?.title === userPanel)
        || dataPanels[0];
      setNotClean(!currentDataPanels);
      setCurrentDataPanels(defaultDataPanel?.data);
    }
  }, [selectedDataPanel, dataPanels, selectedDataPanel?.data?.leftColumn?.length,
    selectedDataPanel?.data?.rightColumn?.length, widget?.dataPanelId]);

  return {
    setDataPanelGridType,
    togglePanelAdd,
    addPanelOpen,
    checkDefault,
    currentDataPanels,
    editMode,
    setEditMode: setEditModeNew,
  };
};

export default useDataPanelWidget;
