export enum WidgetTypes {
  CHART = 'chart',
  SCREENER = 'screener',
  DEEP_LIST = 'deepList',
  STATS_CHART = 'statsChart',
  POSITION_SIZE_CALCULATOR = 'positionSizeCalculator',
  PERFORMANCE_CHART = 'performanceChart',
  HEATMAP = 'heatMap',
  BUBBLE_CHART = 'bubbleChart',
  DATA_PANEL = 'dataPanel',
}

export enum DragWidgetTypes {
  SCREEN,
  TV,
  STATS,
  POSITIONSIZE,
  PERFORMANCE,
  HEATMAP,
  BUBBLE,
  DATA_PANEL
}

export const MENU_WIDGETS = [
  {
    name: 'chartName',
    id: 'chartWidget',
    type: WidgetTypes.CHART,
    typeSelector: WidgetTypes.CHART,
    icon: `${process.env.REACT_APP_IMG_PATH}ChartWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}ChartWidgetDark.svg`,
    dragType: DragWidgetTypes.TV
  },
  {
    name: 'screenerName',
    id: 'screenerWidget',
    type: WidgetTypes.SCREENER,
    typeSelector: WidgetTypes.SCREENER,
    icon: `${process.env.REACT_APP_IMG_PATH}ScreenerWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}ScreenerWidgetDark.svg`,
    dragType: DragWidgetTypes.SCREEN
  },
  {
    name: 'deepListName',
    id: 'deepListWidget',
    type: WidgetTypes.DEEP_LIST,
    typeSelector: WidgetTypes.DEEP_LIST,
    icon: `${process.env.REACT_APP_IMG_PATH}DeepListWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}DeepListWidgetDark.svg`,
    dragType: DragWidgetTypes.SCREEN
  },
  {
    name: 'dataPanelName',
    id: 'dataPanelWidget',
    type: WidgetTypes.DATA_PANEL,
    typeSelector: WidgetTypes.DATA_PANEL,
    icon: `${process.env.REACT_APP_IMG_PATH}dataPanelWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}dataPanelWidgetDark.svg`,
    dragType: DragWidgetTypes.DATA_PANEL
  },
  {
    name: 'statsChartName',
    id: 'statsChartWidget',
    type: WidgetTypes.DEEP_LIST,
    typeSelector: WidgetTypes.STATS_CHART,
    icon: `${process.env.REACT_APP_IMG_PATH}StatsChartWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}StatsChartWidgetDark.svg`,
    dragType: DragWidgetTypes.STATS
  },
  {
    name: 'positionSizeName',
    id: 'positionSizeWidget',
    type: WidgetTypes.POSITION_SIZE_CALCULATOR,
    typeSelector: WidgetTypes.POSITION_SIZE_CALCULATOR,
    icon: `${process.env.REACT_APP_IMG_PATH}positionSizeWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}positionSizeWidgetDark.svg`,
    dragType: DragWidgetTypes.POSITIONSIZE
  },
  {
    name: 'performanceChartName',
    id: 'performanceChartWidget',
    type: WidgetTypes.PERFORMANCE_CHART,
    typeSelector: WidgetTypes.PERFORMANCE_CHART,
    icon: `${process.env.REACT_APP_IMG_PATH}performanceChartWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}performanceChartWidgetDark.svg`,
    dragType: DragWidgetTypes.PERFORMANCE
  },
  {
    name: 'heatMapName',
    id: 'heatMapWidget',
    type: WidgetTypes.HEATMAP,
    typeSelector: WidgetTypes.HEATMAP,
    icon: `${process.env.REACT_APP_IMG_PATH}Heatmap_light.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}heatmap_dark.svg`,
    dragType: DragWidgetTypes.HEATMAP
  },
  {
    name: 'bubbleChartName',
    id: 'bubbleChartWidget',
    type: WidgetTypes.BUBBLE_CHART,
    typeSelector: WidgetTypes.BUBBLE_CHART,
    icon: `${process.env.REACT_APP_IMG_PATH}bubbleChartWidget.svg`,
    iconDark: `${process.env.REACT_APP_IMG_PATH}bubbleChartWidgetDark.svg`,
    dragType: DragWidgetTypes.BUBBLE
  },
];

export const DRAG_MENU_SELECTOR = 'dashboardMenuSelector';
export const DRAG_ITEM_SELECTOR = 'grid-stack-item';
export const RENDERED_ITEM_SELECTOR = 'rendered-item';
export const NO_DRAG_SELECTOR = 'no-drag-item';

export enum ColorLink {
  none = '#DEE1F0',
  blue = '#3A75E8',
  red = '#FF382E',
  yellow = '#FFC239',
  green = '#00B875',
  violet = '#866DEC',
  bloody = '#AC1F1F',
  terracotta = '#E4722C',
  gamboge = '#8A5C00',
  pink = '#E83AAD',
}

export enum WidgetColorsTypes {
  NONE = 'none',
  BLUE = 'blue',
  RED = 'red',
  YELLOW = 'yellow',
  GREEN = 'green',
  VIOLET = 'violet',
  BLOODY = 'bloody',
  TERRACOTTA = 'terracotta',
  GAMBOGE = 'gamboge',
  PINK = 'pink',
}

export enum WidgetColorsNumbers {
  none = 0,
  blue = 1,
  red = 2,
  yellow = 3,
  green = 4,
  violet = 5,
  bloody = 6,
  terracotta = 7,
  gamboge = 8,
  pink = 9,
}

export const HOLDING_TYPE = 'Holding';
