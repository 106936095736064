import { createSlice } from '@reduxjs/toolkit';
import { Dashboard } from '../../pages/Dashboards/types/DashboardTypes';
import { TWidgetQueryState } from '../../utils/Types';

const INITIAL_STATE = {
  widgetMenuOpen: false,
  dataPanelEditMenuOpen: false,
  isDashboardListLoading: true,
  dashboardsList: [] as Dashboard[],
  dashboards: [] as Dashboard[],
  dashboardFolders: [],
  dashboardPresets: [] as Dashboard[],
  dashboardPresetFolders: [],
  itemsDashboard: [],
  itemsDashboardPresets: [],
  widgetConnectionQueries: {} as { [key: string]: TWidgetQueryState },
  widgetChartsConnectionQueries: {} as { [key: string]: TWidgetQueryState },
  widgetsColumnVisible: {} as { [key: string]: boolean },
  widgetsFilterVisible: {} as { [key: string]: boolean },
  globalHoldingSearch: false,
  globalSectorIndustrySearch: false,
  selectedWidgetOnCreateId: null
};

const dashboardsSlice = createSlice({
  name: 'dashboardsSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setWidgetMenuOpen(state, action) {
      state.widgetMenuOpen = action.payload;
    },
    setDataPanelEditMenuOpen(state, action) {
      state.dataPanelEditMenuOpen = action.payload;
    },
    setDashboardsList(state, action) {
      state.dashboardsList = action.payload;
    },
    setWidgetConnectionQueries(state, action) {
      state.widgetConnectionQueries = action.payload;
    },
    removeWidgetConnectionQuery(state, action) {
      const queries = { ...state.widgetConnectionQueries };
      delete queries[Number(action.payload)];

      state.widgetConnectionQueries = queries;
    },
    removeWidgetChartsConnectionQuery(state, action) {
      const queries = { ...state.widgetChartsConnectionQueries };
      delete queries[action.payload as keyof typeof state.widgetChartsConnectionQueries];

      state.widgetChartsConnectionQueries = queries;
    },
    addWidgetChartsConnectionQueries(state, action) {
      return {
        ...state,
        widgetChartsConnectionQueries: {
          ...state.widgetChartsConnectionQueries,
          ...action.payload
        }
      };
    },
    addWidgetQuery(state, action) {
      return {
        ...state,
        widgetConnectionQueries: {
          ...state.widgetConnectionQueries,
          ...action.payload
        }
      };
    },
    setWidgetsColumnVisible(state, action) {
      state.widgetsColumnVisible = action.payload;
    },
    setWidgetsFilterVisible(state, action) {
      state.widgetsFilterVisible = action.payload;
    },
    setGlobalHoldingSearch(state, action) {
      state.globalHoldingSearch = action.payload;
    },
    setGlobalSectorIndustrySearch(state, action) {
      state.globalSectorIndustrySearch = action.payload;
    },
    setSelectedWidgetOnCreateId(state, action) {
      state.selectedWidgetOnCreateId = action.payload;
    },
    cleanWidgetConnectionQueries(state) {
      state.widgetConnectionQueries = {};
    },
    setDashboards(state, action) {
      state.dashboards = action.payload;
      state.isDashboardListLoading = false;
    },
    setDashboardFolders(state, action) {
      state.dashboardFolders = action.payload;
    },
    setDashboardPresets(state, action) {
      state.dashboardPresets = action.payload;
    },
    setDashboardPresetsFolders(state, action) {
      state.dashboardPresetFolders = action.payload;
    },
    setDashboardsItems(state, action) {
      state.itemsDashboard = action.payload;
    },
    setDashboardsItemsPresets(state, action) {
      state.itemsDashboardPresets = action.payload;
    },
  },
});

export const {
  setWidgetMenuOpen,
  setDataPanelEditMenuOpen,
  addWidgetQuery,
  setWidgetsColumnVisible,
  setWidgetsFilterVisible,
  setGlobalHoldingSearch,
  setGlobalSectorIndustrySearch,
  setSelectedWidgetOnCreateId,
  addWidgetChartsConnectionQueries,
  removeWidgetChartsConnectionQuery,
  removeWidgetConnectionQuery,
  cleanWidgetConnectionQueries,
  setDashboards,
  setDashboardPresetsFolders,
  setDashboardFolders,
  setDashboardPresets,
  setDashboardsItems,
  setDashboardsItemsPresets
} = dashboardsSlice.actions;

export default dashboardsSlice;
