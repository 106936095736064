import {
  useEffect, useRef, useState, useContext
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Dashboard, Widget } from '../types/DashboardTypes';
import { IUseDashboardsHook } from '../types/DashboardInterfaces';
import {
  setDashboardPresets,
  setDashboards,
  setGlobalHoldingSearch,
  setGlobalSectorIndustrySearch
} from '../../../store/dashboards/slice';
import RootStateTypes from '../../../store/RootStateTypes';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import useWatchList from '../../../utils/hooks/useWatchList';
import { DASHBOARD_ITEMS, DASHBOARD_ITEM_CHANGE_ORDER } from '../../../constants/paths';
import {
  TEMPLATE_TYPE, VIEW_TYPES, SAVE_USER_STATE_DELAY, PATH_TYPE, SUBSCRIPTION_TYPE
} from '../../../constants/screener';
import $api from '../../../http';
import {
  popUpAction, errorMessageTitleAction,
  messageAction, changeNotificationTypeAction
} from '../../../store/auth/actions';
import useAccount from '../../../utils/hooks/useAccount';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { LAST_DASHBOARDS_TAB } from '../../../constants/storage';
import useAlerts from '../../../components/ChartContainer/hooks/useAlerts';
import { NOTIFICATION_DELAY_TIMES } from '../../../constants/alerts';
import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import { setQueryScreenerId, setDataPanelsQueryId } from '../../../store/tableData/slice';
import SocketContext from '../../../context/SocketContext';
import { messageIdGenerator } from '../../../components/ChartContainer/utils/chartingHelper';
import { WS_CHANNELS } from '../../../constants/socketConnection';
import { checkRoleUser } from '../../../utils/userHelper';
import useDataPanelItems from '../../../components/DataPanel/hooks/useDataPanelItems';
import useWorkerItems from '../../../utils/hooks/useWorkerItems';

type TErrorHandleProps = {
  message: string;
  title: string;
  type: string;
};

const UseDashboards: IUseDashboardsHook = () => {
  const dashboardsListRef = useRef<Dashboard[]>([]);
  const { Connection } = useContext(SocketContext);
  const [isUpdatedAllData, setIsUpdatedAllData] = useState<boolean>(false);
  const { updateStoreHandler } = useLastStateUpdateStore();
  const { t } = useTranslation();
  const { updateUserState } = useAccount();
  const {
    getAlertsNotificationList,
    getAlertsList,
    triggeredAlertsListener,
    getAlertSettings
  } = useAlerts();

  const {
    getDataPanelPreset,
    getDataPanelPresetFolders,
  } = useWorkerItems();

  const {
    getDataPanelItems,
  } = useDataPanelItems();

  const {
    getItems, getItemsPreset,
    getColumnSetsItems, getDashboardsNew
  } = useNewScreen(() => undefined);
  const { getWatchLists } = useWatchList();
  const dispatch = useDispatch();
  const route = useRouteMatch();

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const dashboardsList = useSelector((state: RootStateTypes) => state.dashboardsState.dashboards, shallowEqual);
  const dashboardsListPresets = useSelector(
    (state: RootStateTypes) => state.dashboardsState.dashboardPresets, shallowEqual
  );
  const selectedDashboardId = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.selectedDashboardId,
    shallowEqual
  );
  const globalHoldingSearch = useSelector(
    (state: RootStateTypes) => state.dashboardsState.globalHoldingSearch, shallowEqual
  );
  const globalSectorIndustrySearch = useSelector(
    (state: RootStateTypes) => state.dashboardsState.globalSectorIndustrySearch, shallowEqual
  );
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const lastStateId = useSelector((state: RootStateTypes) => state.accountState.lastStateId);
  const tabsList = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);
  const itemsColumnSets = useSelector((state: RootStateTypes) => state.newScreenState.itemsColumnSets, shallowEqual);
  const itemsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.itemsNewScreen, shallowEqual);
  const presetsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.presetsNewScreen, shallowEqual);
  const updatedLastState = useSelector((state: RootStateTypes) => state.accountState.updatedLastState, shallowEqual);
  const queryScreenerId = useSelector((state: RootStateTypes) => state.tableDataState.queryScreenerId, shallowEqual);
  const dataPanelsQueryId = useSelector(
    (state: RootStateTypes) => state.tableDataState.dataPanelsQueryId, shallowEqual
  );

  const debouncedUpdateLastState = useDebouncedCallback();

  const setCurrentDashboard = (id: number) => {
    updateStoreHandler(LAST_DASHBOARDS_TAB, id);
  };

  const clenUpOldConnections = () => {
    if (queryScreenerId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: messageIdGenerator(), screenerId: queryScreenerId });
      dispatch(setQueryScreenerId(null));
    }
    if (dataPanelsQueryId) {
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: messageIdGenerator(), screenerId: dataPanelsQueryId });
      dispatch(setDataPanelsQueryId(null));
    }
  };

  const successHandler = (message: string, title: string) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const errorHandler = ({ message, title, type }: TErrorHandleProps) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type }));
  };

  const createDashboard = (name: string, isPreset: boolean) => {
    $api.post(
      DASHBOARD_ITEMS,
      {
        title: name,
        type: isPreset ? TEMPLATE_TYPE.DASHBOARDS_PRESET : TEMPLATE_TYPE.DASHBOARDS,
        viewType: VIEW_TYPES.TABLE,
        data: {
          widgets: [],
        },
      }
    ).then((resp) => {
      successHandler(resp?.data?.title, t('successCreateDashboard'));
      getDashboardsNew();
      getDashboardsNew(true);
    }).catch(() => {
      errorHandler({
        message: t('errorCreateDashboard'),
        title: t('error'),
        type: t('error'),
      });
    });
  };

  const duplicateDashboard = (dashboard: Dashboard, title: string, folderId: string | null | undefined,
    closeModal: () => void) => {
    const cloneWidget = dashboard?.data?.widgets?.map((widget: Widget) => {
      const newWidget = { ...widget };
      newWidget.id = `${messageIdGenerator()}`;
      return newWidget;
    });

    $api.post(
      DASHBOARD_ITEMS,
      {
        title,
        type: TEMPLATE_TYPE.DASHBOARDS,
        viewType: VIEW_TYPES.TABLE,
        folder: folderId,
        data: { ...dashboard.data, widgets: cloneWidget },
      }
    ).then((resp) => {
      successHandler(resp?.data?.title, t('successDuplicateDashboard'));
      getDashboardsNew();
      getDashboardsNew(true);
      closeModal();
    }).catch(() => {
      errorHandler({
        type: t('error'),
        message: t('changeName'),
        title: `The name ${dashboard?.title} already exists`,
      });
    });
  };

  const updateDashboard = (dashboard: Dashboard) => {
    $api.patch(
      `${DASHBOARD_ITEMS}/${dashboard.id}`,
      {
        title: dashboard.title,
        description: dashboard.description,
        data: dashboard.data,
      }
    ).catch(() => {
      errorHandler({
        message: t('errorUpdateDashboard'),
        title: t('error'),
        type: t('errorType'),
      });
    });
  };

  const removeDashboard = (id: number) => {
    $api.delete(`${DASHBOARD_ITEMS}/${id}`)
      .then(() => {
        successHandler(t('successDeleteDashboard'), t('success'));
        const dashboardListNew = dashboardsListRef.current.length ? dashboardsListRef.current
          : dashboardsList;
        const removeIndex = dashboardListNew.findIndex((item: Dashboard) => item.id === id);
        const lastIndex = dashboardListNew.length - 1;
        if (removeIndex > 0 && lastIndex > 0 && removeIndex === lastIndex) {
          updateStoreHandler(LAST_DASHBOARDS_TAB, dashboardListNew[removeIndex - 1].id);
        }
        if (removeIndex === 0 && lastIndex === 0) {
          updateStoreHandler(LAST_DASHBOARDS_TAB, 0);
        }
        if (removeIndex + 1 <= lastIndex) {
          updateStoreHandler(LAST_DASHBOARDS_TAB, dashboardListNew[removeIndex + 1].id);
        }
        getDashboardsNew();
        getDashboardsNew(true);
      })
      .catch(() => {
        errorHandler({
          message: t('errorDeleteDashboard'),
          title: t('error'),
          type: t('errorType'),
        });
      });
  };

  const updateWidget = (widget: Widget, dashboardId: number) => {
    const cloneDashboards = [...dashboardsListRef.current, ...dashboardsListPresets];
    const newDashboards = cloneDashboards.map((dashboard: Dashboard) => {
      if (dashboard.id === dashboardId && (adminRole || (dashboard.type !== TEMPLATE_TYPE.DASHBOARDS_PRESET))) {
        const newWidgets = dashboard.data.widgets.map((item: Widget) => {
          if (item.id === widget.id) {
            return widget;
          }
          return item;
        });
        updateDashboard({ ...dashboard, data: { widgets: newWidgets } });
        return {
          ...dashboard,
          data: {
            widgets: newWidgets,
          }
        };
      }
      return dashboard;
    });
    dispatch(setDashboards(newDashboards.filter(
      (preset) => preset.type === TEMPLATE_TYPE.DASHBOARDS
    )));
    dispatch(setDashboardPresets(newDashboards.filter(
      (preset) => preset.type === TEMPLATE_TYPE.DASHBOARDS_PRESET
    )));
  };

  const renameDashboard = (title: string, id: number,) => {
    const newDashboards = [...dashboardsList, ...dashboardsListPresets].map((dashboard: Dashboard) => {
      if (dashboard.id === id) {
        updateDashboard({ ...dashboard, title });
        return {
          ...dashboard,
          title,
        };
      }
      return dashboard;
    });
    dispatch(setDashboards(newDashboards.filter(
      (preset) => preset.type === TEMPLATE_TYPE.DASHBOARDS
    )));
    dispatch(setDashboardPresets(newDashboards.filter(
      (preset) => preset.type === TEMPLATE_TYPE.DASHBOARDS_PRESET
    )));
  };

  const updateDashboardInStore = (dashboard: Dashboard) => {
    const newDashboards = [...dashboardsListRef?.current, ...dashboardsListPresets].map((item: Dashboard) => {
      if (item.id === dashboard.id && (adminRole || (dashboard.type !== TEMPLATE_TYPE.DASHBOARDS_PRESET))) {
        updateDashboard(dashboard);
        return dashboard;
      }
      if (item.id === dashboard.id && dashboard.type === TEMPLATE_TYPE.DASHBOARDS_PRESET) {
        return dashboard;
      }
      return item;
    });
    dispatch(setDashboards(newDashboards.filter(
      (preset) => preset.type === TEMPLATE_TYPE.DASHBOARDS
    )));
    dispatch(setDashboardPresets(newDashboards.filter(
      (preset) => preset.type === TEMPLATE_TYPE.DASHBOARDS_PRESET
    )));
  };

  const saveOrder = (itemId: number, placeAfterId: number) => {
    $api.post(`${DASHBOARD_ITEMS}/${DASHBOARD_ITEM_CHANGE_ORDER}`,
      {
        itemId,
        placeAfterId
      }).then(() => getDashboardsNew()).catch();
  };

  const handleSetGlobalHoldingSearch = (value: boolean) => {
    dispatch(setGlobalHoldingSearch(value));
  };

  const handleSetGlobalSectorIndustrySearch = (value: boolean) => {
    dispatch(setGlobalSectorIndustrySearch(value));
  };

  useEffect(() => {
    if (dashboardsList.length && !selectedDashboardId) {
      const firstDashboard: Dashboard = dashboardsList[0];
      updateStoreHandler(LAST_DASHBOARDS_TAB, firstDashboard?.id);
    }
    if (dashboardsListRef.current?.length && (dashboardsListRef.current?.length < dashboardsList.length)) {
      const firstDashboard: Dashboard = dashboardsList[0];
      updateStoreHandler(LAST_DASHBOARDS_TAB, firstDashboard?.id);
    }
    dashboardsListRef.current = dashboardsList;
  }, [dashboardsList]);

  useEffect(() => {
    if (updatedLastState) {
      debouncedUpdateLastState(updateUserState, SAVE_USER_STATE_DELAY, userLastState, lastStateId);
    }
  }, [userLastState]);

  useEffect(() => {
    if (tabsList.length
      && itemsColumnSets.length
      && presetsNewScreen.length
      && dashboardsList
    ) {
      setIsUpdatedAllData(true);
    }
  }, [tabsList, itemsColumnSets, itemsNewScreen, presetsNewScreen, dashboardsList]);

  useEffect(() => {
    if (route?.path === PATH_TYPE.DASHBOARD) {
      getDashboardsNew();
      getDashboardsNew(true);
      getItemsPreset();
      getItems();
      getWatchLists();
      getDataPanelItems();
      getDataPanelPreset();
      getDataPanelPresetFolders();
      getItemsPreset(true);
      getColumnSetsItems();
      getColumnSetsItems(true);
      getAlertsNotificationList();
      getAlertsList();
      getAlertSettings();
      clenUpOldConnections();
    }
    const interval = setInterval(() => {
      triggeredAlertsListener();
    }, NOTIFICATION_DELAY_TIMES.TICKS);
    return () => {
      clearInterval(interval);
      dashboardsListRef.current = [];
    };
  }, []);

  return {
    setCurrentDashboard,
    updateWidget,
    createDashboard,
    updateDashboard,
    removeDashboard,
    renameDashboard,
    updateDashboardInStore,
    saveOrder,
    globalHoldingSearch,
    handleSetGlobalHoldingSearch,
    globalSectorIndustrySearch,
    handleSetGlobalSectorIndustrySearch,
    isUpdatedAllData,
    duplicateDashboard
  };
};

export default UseDashboards;
