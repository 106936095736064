export const OK_STATUS = 'ok';
export const STATUS_200 = 200;
export const ERROR = 'Error';

export const DASHBOARD_ITEM_FOLDER_EXISTS = '1025: Dashboard item folder exists.';
export const DASHBOARD_ITEM_EXISTS = '1024: Dashboard item exists.';
export const WATCHLIST_FULL = '1013: Invalid Params.';

export const UNDEFINED_SOCKET_CONNECTION = 'UNDEFINED_SOCKET';
export const UNDEFINED_SCREENER = 'UNDEFINED_SCREENER';
