import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from '../sass/DataPanel.module.scss';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/screener/edit_square.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/screener/trash.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/images/icons/Check.svg';
import DataPanelItemEdit from './DataPanelItemEdit';
import { DATA_PANEL_ACTION_TYPES, MAX_CATEGORY_NAME } from '../dataPanelConstants';
import TextWithTooltip from './TextWithTooltip';

const DataPanelContentEditItems = ({
  data, onRemove,
  onRename, selected,
  onSelect, removeDataPoint,
  getTitle, updateCategoryData,
  setDisableDrag, checkUniqueCategory,
  saveDataPanels, widget
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState(data.title);
  const [isTitleInValid, setIsTitleInValid] = useState(false);

  const handleEdit = () => {
    setIsEdit(true);
  };

  const checkSpaces = (str) => {
    const checkTitle = str;
    return !!checkTitle.replace(/\s/g, '')?.length;
  };

  const handleSave = () => {
    if (title === data.title) {
      setIsEdit(false);
      setIsTitleInValid(false);
      return;
    }
    if (title !== ''
      && title.length <= MAX_CATEGORY_NAME
      && checkUniqueCategory(title)
      && checkSpaces(title)) {
      setIsEdit(false);
      setIsTitleInValid(false);
    } else {
      setIsTitleInValid(true);
    }
    onRename(data.title, title);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSelect = () => {
    onSelect(data.title);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination, } = result;
    const indexFrom = source.index;
    const indexTo = destination.index;
    const category = data.title;
    const { columns } = data;
    columns.splice(indexTo, 0, columns.splice(indexFrom, 1)[0]);
    updateCategoryData(category, columns);
    saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
  };

  const disableClass = () => {
    return title === '' || !checkSpaces(title) ? styles.disabled : '';
  };

  useEffect(() => {
    if (isTitleInValid && title?.length && title.length <= MAX_CATEGORY_NAME) {
      setIsTitleInValid(false);
    }
  }, [title]);

  useEffect(() => {
    if (!selected) {
      setIsEdit(false);
      setTitle(data.title);
    }
  }, [selected]);

  return (
    <div
      aria-hidden
      className={`${styles.dataPanelContentCard} ${widget?.id && styles.dataPanelContentCardDashboard}
       ${selected && styles.cardSelected}`}
      onClick={handleSelect}
    >
      <div className={styles.dataPanelContentCardHeader}>
        <div className={`${styles.dataPanelContentCardHeaderTitle} ${isTitleInValid && styles.NoValid}`}>
          {!isEdit && (
            <div className={styles.dataPanelContentCardHeaderDragIcon}>
              <DndControl />
            </div>
          )}
          {isEdit ? (
            <input
              name="EditTitle"
              onChange={handleTitleChange}
              value={title}
            />
          ) : <TextWithTooltip title={data.title} /> }
        </div>
        <div className={styles.dataPanelContentCardHeaderActions}>
          {isEdit ? (
            <CheckedIcon
              className={`${styles.dataPanelContentCardHeaderActionsBtn} ${disableClass()}`}
              onClick={handleSave}
            />
          ) : (
            <IconEdit
              onClick={handleEdit}
              className={`${styles.dataPanelContentCardHeaderActionsBtn} ${styles.editBut}`}
            />
          )}
          <IconRemove
            onClick={() => onRemove(data.title)}
            className={styles.dataPanelContentCardHeaderActionsBtn}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>

        <Droppable
          droppableId={data.title}
        >
          {(provided) => (
            <div
              aria-hidden
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.dataPanelContentCardItems}
              onMouseEnter={() => setDisableDrag(true)}
              onMouseLeave={() => setDisableDrag(false)}
            >
              {data.columns.map((item, index) => (
                <Draggable
                  key={[item, 'point'].join('_')}
                  draggableId={`${item}`}
                  index={index}
                >
                  {/* eslint-disable-next-line no-shadow */}
                  {(provided) => (
                    <div
                      ref={provided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.dragHandleProps}
                    >
                      <DataPanelItemEdit
                        itemId={item}
                        removeItem={removeDataPoint}
                        getTitle={getTitle}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

DataPanelContentEditItems.propTypes = {
  saveDataPanels: PropTypes.func.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  widget: PropTypes.shape({
    id: PropTypes.number,
  }),
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  removeDataPoint: PropTypes.func.isRequired,
  getTitle: PropTypes.func.isRequired,
  updateCategoryData: PropTypes.func.isRequired,
  setDisableDrag: PropTypes.func.isRequired,
  checkUniqueCategory: PropTypes.func.isRequired,
};

DataPanelContentEditItems.defaultProps = {
  selected: false,
  widget: {},
};

export default DataPanelContentEditItems;
