import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import $api from '../../../http';
import { errorMessageAction } from '../../../store/account/actions';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../../store/auth/actions';
import { DASHBOARD_FOLDERS, DASHBOARD_ITEM_TYPE_DATA_PANEL, DASHBOARD_ITEMS, } from '../../../constants/paths';
import numberConstant from '../../../constants/numberConstants';
import { CATEGORY_MAX_LENGTH, DATA_PANEL_ACTION_TYPES, MAX_CATEGORY_NAME } from '../dataPanelConstants';
import useWorkerItems from '../../../utils/hooks/useWorkerItems';
import { fillSpeedValuesHandler } from '../../../utils/hooks/useSpeedCheckup';
import { SELECTED_DATA_PANEL_ID } from '../../../constants/storage';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { userPanel } from '../../../constants/account';

const useDataPanelItems = (widget) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    getDataPanelFolders,
    getDataPanel,
  } = useWorkerItems();
  const { updateStoreHandler } = useLastStateUpdateStore();
  const selectedDataPanelScreener = useSelector(
    (state) => state.newScreenState?.selectedDataPanel, shallowEqual
  );
  const selectedDataPanelId = useSelector(
    (state) => state.accountState?.userSettings?.selectedDataPanelId, shallowEqual
  );
  const dataPanels = useSelector(
    (state) => state.newScreenState?.dataPanels, shallowEqual
  );
  const dataPanelsPresets = useSelector(
    (state) => state.newScreenState?.dataPanelsPresets, shallowEqual
  );
  const [selectedDataPanel, setSelectedDataPanel] = useState(
    widget?.id ? [...dataPanels, ...dataPanelsPresets].find(
      (dataPanel) => dataPanel?.id === widget?.dataPanelId
    ) : selectedDataPanelScreener
  );

  const successHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const errorHandler = ({ message, title, type }) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type }));
  };

  const checkUniqueCategory = (category) => {
    const userDataPanel = selectedDataPanel;
    const leftColumn = userDataPanel.data.leftColumn.length
      ? userDataPanel.data.leftColumn?.filter((item) => item.title === category) : [];
    const rightColumn = userDataPanel.data.rightColumn.length
      ? userDataPanel.data.rightColumn?.filter((item) => item.title === category) : [];
    return leftColumn.length === 0 && rightColumn.length === 0;
  };

  const getDataPanelItems = (itemId) => {
    const start = Date.now();
    try {
      $api.get(`${DASHBOARD_FOLDERS}?${DASHBOARD_ITEM_TYPE_DATA_PANEL}&page=0&limit=${numberConstant.limitItems}`)
        .then((resFolders) => {
          getDataPanelFolders(resFolders.data.items, resFolders.data.count);
          const data = resFolders.data.items.map((itemFolder) => {
            return {
              id: itemFolder.id, items: [], title: itemFolder.name, type: 'folder'
            };
          });
          $api.get(`${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_DATA_PANEL}&limit=${numberConstant.limitItems}`)
            .then((resItems) => {
              getDataPanel(resItems.data.items, resItems.data.items.length);
              resItems.data.items.forEach((item) => {
                if (item.id === (itemId || selectedDataPanelId)) {
                  dispatch(setSelectedDataPanel(item));
                  setSelectedDataPanel(item);
                  updateStoreHandler(SELECTED_DATA_PANEL_ID, item?.id);
                }

                const findIndex = data.findIndex((subItem) => subItem.id === item.folder?.id);
                if (findIndex !== -1) {
                  data[findIndex].items.push(item);
                } else {
                  data.push({ ...item, items: [] });
                }
              });

              if (!itemId && !selectedDataPanelId) {
                const defaultDataPanel = resItems.data.items?.find((dataPanel) => dataPanel?.title === userPanel)
                  || dataPanels[0];
                dispatch(setSelectedDataPanel(defaultDataPanel));
                setSelectedDataPanel(defaultDataPanel);
                updateStoreHandler(SELECTED_DATA_PANEL_ID, defaultDataPanel?.id);
              }

              // speed check temporary data
              const end = Date.now();
              const speedCheck = end - start;
              fillSpeedValuesHandler('get_data_panels_data', speedCheck);
              // eslint-disable-next-line no-console
              console.log('=> get_data_panels_data', speedCheck);
            });
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const updateUserDataPanel = (id, data, type) => {
    $api.patch(`${DASHBOARD_ITEMS}/${id}`, { data }).then((res) => {
      getDataPanelItems(id);
      dispatch(setSelectedDataPanel(res.data));
      setSelectedDataPanel(res.data);
      updateStoreHandler(SELECTED_DATA_PANEL_ID, res.data?.id);
      let message = '';
      let title = '';
      switch (type) {
        case DATA_PANEL_ACTION_TYPES.REMOVE_CATEGORY:
          message = t('dataPanelSuccessRemove');
          title = t('dataPanelSuccessRemoveTitle');
          break;
        case DATA_PANEL_ACTION_TYPES.RESTORE_DEFAULTS:
          message = t('dataPanelSuccessRestore');
          title = t('dataPanelRestoreTitle');
          break;
        default:
          message = t('dataPanelSuccess');
          title = t('dataPanelSuccessTitle');
          break;
      }
      successHandler(message, title);
    });
  };

  const saveDataPanels = (type) => {
    if (selectedDataPanel?.id) {
      updateUserDataPanel(selectedDataPanel.id, selectedDataPanel.data, type);
    }
  };

  const updateUserDataPanels = (dataPanel) => {
    dispatch(setSelectedDataPanel(dataPanel));
    setSelectedDataPanel(dataPanel);
    updateStoreHandler(SELECTED_DATA_PANEL_ID, dataPanel?.id);
  };

  const restoreDefaults = () => {
    const userDataPanel = selectedDataPanel;
    userDataPanel.data = selectedDataPanel.data;
    dispatch(setSelectedDataPanel(userDataPanel));
    setSelectedDataPanel(userDataPanel);
    const defaultDataPanel = dataPanels?.find((dataPanel) => dataPanel?.isDefault)
    || dataPanels?.find((dataPanel) => dataPanel?.title === 'Default') || dataPanels[0];
    updateUserDataPanel(userDataPanel.id, defaultDataPanel?.data, DATA_PANEL_ACTION_TYPES.RESTORE_DEFAULTS);
    updateStoreHandler(SELECTED_DATA_PANEL_ID, userDataPanel?.id);
  };

  const removeCategory = (category) => {
    const userDataPanel = selectedDataPanel;
    const leftColumn = userDataPanel.data.leftColumn.filter((item) => item.title !== category);
    const rightColumn = userDataPanel.data.rightColumn.filter((item) => item.title !== category);
    const updatedPanels = {
      leftColumn,
      rightColumn,
    };
    userDataPanel.data = updatedPanels;
    if (!widget) {
      updateUserDataPanels(userDataPanel);
    } else {
      updateUserDataPanel(selectedDataPanel.id, userDataPanel.data);
    }
    saveDataPanels(DATA_PANEL_ACTION_TYPES.REMOVE_CATEGORY);
  };

  const renameCategory = (category, newCategory) => {
    if (newCategory?.length > MAX_CATEGORY_NAME) {
      errorHandler({
        message: t('maxCategoryName', { max: MAX_CATEGORY_NAME }),
        title: t('maxCategoryNameTitle'),
        type: t('error')
      });
      return;
    }
    if (!newCategory || newCategory.replace(/\s/g, '').length === 0) {
      errorHandler(
        {
          message: t('emptyCategoryName'),
          title: t('emptyCategoryNameTitle'),
          type: t('error')
        }
      );
      return;
    }
    if (!checkUniqueCategory(newCategory)) {
      errorHandler(
        {
          message: t('categoryUniqueError'),
          title: t('categoryUniqueErrorTitle'),
          type: t('error')
        }
      );
      return;
    }
    const userDataPanel = selectedDataPanel;
    const updateColumn = (column) => {
      return column
        ? column.map((item) => {
          if (item.title === category) {
            return { ...item, title: newCategory };
          }
          return item;
        }) : column;
    };
    const leftColumn = updateColumn(userDataPanel?.data?.leftColumn);
    const rightColumn = updateColumn(userDataPanel?.data?.rightColumn);
    userDataPanel.data = {
      leftColumn,
      rightColumn,
    };
    if (!widget) {
      updateUserDataPanels(userDataPanel);
    } else {
      updateUserDataPanel(selectedDataPanel.id, userDataPanel.data);
    }
    saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
  };

  const addNewCategory = (newCategory) => {
    const userDataPanel = selectedDataPanel;
    userDataPanel.data.rightColumn.push({ title: newCategory, columns: [] });
    if (!widget) updateUserDataPanels(userDataPanel);
    saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
  };

  const updatePanelsData = (data) => {
    const userDataPanel = selectedDataPanel;
    userDataPanel.data = data;
    updateUserDataPanels(userDataPanel);
  };

  const updateCategoryData = (category, data) => {
    const userDataPanel = selectedDataPanel;
    const leftColumn = userDataPanel.data.leftColumn.map((item) => {
      if (item.title === category) {
        return { ...item, columns: data };
      }
      return item;
    });
    const rightColumn = userDataPanel.data.rightColumn.map((item) => {
      if (item.title === category) {
        return { ...item, columns: data };
      }
      return item;
    });
    const updatedPanels = {
      leftColumn,
      rightColumn
    };
    userDataPanel.data = updatedPanels;
    if (!widget) {
      updateUserDataPanels(userDataPanel);
    } else {
      updateUserDataPanel(selectedDataPanel.id, userDataPanel.data);
    }
  };

  const maxCategoryError = () => {
    errorHandler({
      message: t('maxCategoryError', { max: CATEGORY_MAX_LENGTH }),
      title: t('maxCategoryErrorTitle'),
      type: t('error')
    });
  };

  useEffect(() => {
    if (widget?.dataPanelId) {
      setSelectedDataPanel([...dataPanels, ...dataPanelsPresets].find(
        (dataPanel) => dataPanel?.id === widget?.dataPanelId
      ));
    } else {
      const defaultDataPanel = selectedDataPanelScreener
        || dataPanels?.find((dataPanel) => dataPanel?.title === userPanel)
        || dataPanels[0];
      setSelectedDataPanel(defaultDataPanel);
    }
  }, [widget, selectedDataPanelScreener]);

  return {
    getDataPanelItems,
    updateUserDataPanels,
    updateUserDataPanel,
    restoreDefaults,
    removeCategory,
    renameCategory,
    addNewCategory,
    updatePanelsData,
    updateCategoryData,
    saveDataPanels,
    maxCategoryError,
    errorHandler,
    checkUniqueCategory
  };
};

export default useDataPanelItems;
